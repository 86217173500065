import { WebFileSystemDirectoryHandle } from "./file-system-directory-handle";
import { WebFileSystemFileHandle } from "./file-system-file-handle";

export class WebFileSystemProvider {
  async showOpenFilePicker(options) {
    const handles = await self.showOpenFilePicker(options);
    return handles.map((handle) => new WebFileSystemFileHandle(handle));
  }

  async showSaveFilePicker(options) {
    const handle = await self.showSaveFilePicker(options);
    return new WebFileSystemFileHandle(handle);
  }

  async showDirectoryPicker(options) {
    const handle = await self.showDirectoryPicker(options);
    return new WebFileSystemDirectoryHandle(handle);
  }

  async dataTransferItemToHandle(dataTransferItem) {
    const handle = await dataTransferItem.getAsFileSystemHandle();
    return this._handleToWebHandle(handle);
  }

  serializeHandle(handle) {
    return handle._handle;
  }

  deserializeHandle(serialized) {
    return this._handleToWebHandle(serialized);
  }

  _handleToWebHandle(handle) {
    switch (handle.kind) {
      case "file":
        return new WebFileSystemFileHandle(handle);
      case "directory":
        return new WebFileSystemDirectoryHandle(handle);
    }
  }

  async getFileHandle(filePath) {
    // Split the file path into directory parts and file name
    const pathParts = filePath.split("/");
    const fileName = pathParts.pop();

    // Start from the root directory handle
    let directoryHandle = await this.showDirectoryPicker();

    // Traverse directories based on pathParts
    for (const part of pathParts) {
      directoryHandle = await directoryHandle.getDirectoryHandle(part);
    }

    // Finally, return the file handle for the file
    return directoryHandle.getFileHandle(fileName);
  }

  // without a file picker or getFileHandle
  async checkFileExists(filePath) {
    try {
      await this.getFileHandle(filePath);
      return true;
    } catch (e) {
      return false;
    }
  }

  get supported() {
    return self && "showOpenFilePicker" in self;
  }
}
