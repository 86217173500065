import * as windows1252 from "windows-1252";

import {
  encodeStringName,
  decodeString,
  decodeStringClassic,
  decodeStringNew,
} from "./eo-decode";
import { encodeString } from "./eo-encode";
import { findMostFrequent } from "../util/array-utils";
import { layerInfo } from "./layer-info";

export const MapType = {
  Normal: 0,
  PK: 3,
};

export const MapEffect = {
  None: 0,
  HPDrain: 1,
  TPDrain: 2,
  Quake1: 3,
  Quake2: 4,
  Quake3: 5,
  Quake4: 6,
};

export const MusicControl = {
  InterruptIfDifferentPlayOnce: 0,
  InterruptPlayOnce: 1,
  FinishPlayOnce: 2,
  InterruptIfDifferentPlayRepeat: 3,
  InterruptPlayRepeat: 4,
  FinishPlayRepeat: 5,
  InterruptPlayNothing: 6,
};

export const TileSpec = {
  Wall: 0,
  ChairDown: 1,
  ChairLeft: 2,
  ChairRight: 3,
  ChairUp: 4,
  ChairDownRight: 5,
  ChairUpLeft: 6,
  ChairAll: 7,
  JammedDoor: 8,
  Chest: 9,
  Gather: 14,
  BankVault: 16,
  NPCBoundary: 17,
  MapEdge: 18,
  FakeWall: 19,
  Board1: 20,
  Board2: 21,
  Board3: 22,
  Board4: 23,
  Board5: 24,
  Board6: 25,
  Board7: 26,
  Board8: 27,
  Jukebox: 28,
  Jump: 29,
  Water: 30,
  Arena: 32,
  AmbientSource: 33,
  AmbientSource2: 34,
  AmbientSource3: 35,
  Spikes1: 36,
  Spikes2: 37,
  Spikes3: 38,
  FishingDown: 61,
  FishingLeft: 62,
  FishingUp: 63,
  FishingRight: 64,
  VultTypoWall: 173,
};

function bytesToString(bytes) {
  let decodedBytes = decodeStringNew(bytes);

  let length;
  for (length = 0; length < decodedBytes.length; ++length) {
    if (decodedBytes[length] === 0x00 || decodedBytes[length] === 0xff) {
      break;
    }
  }

  let characters = decodedBytes.slice(0, length);

  return windows1252.decode(characters);
}

function stringToBytes(string, length) {
  if (length === undefined) {
    length = string.length;
  }

  let characters = windows1252.encode(string);

  let array = [];
  for (let i = 0; i < length; ++i) {
    if (i < characters.length) {
      array.push(characters[i]);
    } else {
      array.push(0xff);
    }
  }

  return encodeString(Uint8Array.from(array));
}

export class MapGather {
  constructor(
    x,
    y,
    type,
    hit_count,
    unk3,
    blend_id,
    tile_id,
    graphic_id,
    item_id,
    time,
    unk11,
    max_amount
  ) {
    this.x = x;
    this.y = y;
    this.type = type;
    this.hit_count = hit_count;
    this.unk3 = unk3;
    this.blend_id = blend_id;
    this.tile_id = tile_id;
    this.graphic_id = graphic_id;
    this.id = item_id;
    this.time = time;
    this.unk11 = unk11;
    this.max_amount = max_amount;
  }

  static read(reader) {
    let x = reader.getChar();
    let y = reader.getChar();
    let type = reader.getChar();
    let hit_count = reader.getChar();
    let unk3 = reader.getChar();

    let blend_id = reader.getShort();
    let tile_id = reader.getShort();
    let graphic_id = reader.getShort();
    let item_id = reader.getShort();

    let time = reader.getShort();
    let unk11 = reader.getChar();
    let max_amount = reader.getChar();

    return new MapGather(
      x,
      y,
      type,
      hit_count,
      unk3,
      blend_id,
      tile_id,
      graphic_id,
      item_id,
      time,
      unk11,
      max_amount
    );
  }

  write(builder) {
    builder.addChar(this.x);
    builder.addChar(this.y);
    builder.addChar(this.type);
    builder.addChar(this.hit_count);
    builder.addChar(this.unk3);

    builder.addShort(this.blend_id);
    builder.addShort(this.tile_id);
    builder.addShort(this.graphic_id);
    builder.addShort(this.id);

    builder.addShort(this.time);
    builder.addChar(this.unk11);
    builder.addChar(this.max_amount);
  }
}

export class MapItem {
  constructor(x, y, key, chestSlot, id, spawnTime, amount) {
    this.x = x;
    this.y = y;
    this.key = key;
    this.chestSlot = chestSlot;
    this.id = id;
    this.spawnTime = spawnTime;
    this.amount = amount;
  }

  static read(reader) {
    let x = reader.getChar();
    let y = reader.getChar();
    let key = reader.getShort();
    let chestSlot = reader.getChar();
    let id = reader.getShort();
    let spawnTime = reader.getShort();
    let amount = reader.getThree();

    return new MapItem(x, y, key, chestSlot, id, spawnTime, amount);
  }

  write(builder) {
    builder.addChar(this.x);
    builder.addChar(this.y);
    builder.addShort(this.key);
    builder.addChar(this.chestSlot);
    builder.addShort(this.id);
    builder.addShort(this.spawnTime);
    builder.addThree(this.amount);
  }
}

export class MapNPC {
  constructor(
    x,
    y,
    id,
    interaction_id,
    boundary_x,
    boundary_y,
    courage,
    unk5,
    spawnType,
    spawnTime,
    unk6,
    amount
  ) {
    this.x = x;
    this.y = y;
    this.id = id;
    this.interaction_id = interaction_id;
    this.boundary_x = boundary_x;
    this.boundary_y = boundary_y;
    this.courage = courage;
    this.unk5 = unk5;
    this.spawnType = spawnType;
    this.spawnTime = spawnTime;
    this.unk6 = unk6;
    this.amount = amount;
  }

  static read(reader) {
    let x = reader.getChar();
    let y = reader.getChar();
    let id = reader.getShort();
    let interaction_id = reader.getChar();
    let boundary_x = reader.getChar();
    let boundary_y = reader.getChar();
    let courage = reader.getChar();
    let unk5 = reader.getChar();
    let spawnType = reader.getChar();
    let spawnTime = reader.getShort();
    let unk6 = reader.getChar();
    let amount = reader.getChar();

    return new MapNPC(
      x,
      y,
      id,
      interaction_id,
      boundary_x,
      boundary_y,
      courage,
      unk5,
      spawnType,
      spawnTime,
      unk6,
      amount
    );
  }

  write(builder) {
    builder.addChar(this.x);
    builder.addChar(this.y);
    builder.addShort(this.id);
    builder.addChar(this.interaction_id);
    builder.addChar(this.boundary_x);
    builder.addChar(this.boundary_y);
    builder.addChar(this.courage);
    builder.addChar(this.unk5);
    builder.addChar(this.spawnType);
    builder.addShort(this.spawnTime);
    builder.addChar(this.unk6);
    builder.addChar(this.amount);
  }
}

export class MapSpecDoor {
  constructor(x, y, id, type) {
    this.x = x;
    this.y = y;
    this.id = id;
    this.type = type;
  }

  static read(reader) {
    let x = reader.getChar();
    let y = reader.getChar();
    let id = reader.getShort();
    let type = reader.getChar();

    return new MapSpecDoor(x, y, id, type);
  }

  write(builder) {
    builder.addChar(this.x);
    builder.addChar(this.y);
    builder.addShort(this.id);
    builder.addChar(this.type);
  }
}

export class MapLegacyDoorKey {
  constructor(x, y, key) {
    this.x = x;
    this.y = y;
    this.key = key;
  }

  static read(reader) {
    let x = reader.getChar();
    let y = reader.getChar();
    let key = reader.getShort();

    return new MapLegacyDoorKey(x, y, key);
  }

  write(builder) {
    builder.addChar(this.x);
    builder.addChar(this.y);
    builder.addShort(this.key);
  }
}

export class MapWarp {
  constructor(spec, door, quest_id, map, x, y) {
    this.spec = spec;
    this.door = door;
    this.quest_id = quest_id;
    this.map = map;
    this.x = x;
    this.y = y;
  }

  static read(reader) {
    let spec = reader.getChar();
    let door = reader.getChar();
    let quest_id = reader.getShort();

    let map = reader.getShort();
    let x = reader.getChar();
    let y = reader.getChar();

    return new MapWarp(spec, door, quest_id, map, x, y);
  }

  write(builder) {
    builder.addChar(this.spec);
    builder.addChar(this.door);
    builder.addShort(this.quest_id);
    builder.addShort(this.map);
    builder.addChar(this.x);
    builder.addChar(this.y);
  }
}

export class MapTileEffect {
  constructor(
    type,
    color,
    scale,
    intensity,
    daymode_min,
    pulse_mode,
    unk9,
    xoffset,
    yoffset,
    unk11,
    sub_light_mode,
    sub_light_scale,
    sub_light_intensity
  ) {
    this.type = type;
    this.color = color;
    this.scale = scale;
    this.intensity = intensity;
    this.daymode_min = daymode_min;
    this.pulse_mode = pulse_mode;
    this.unk9 = unk9;
    this.xoffset = xoffset;
    this.yoffset = yoffset;
    this.unk11 = unk11;
    this.sub_light_mode = sub_light_mode;
    this.sub_light_scale = sub_light_scale;
    this.sub_light_intensity = sub_light_intensity;
  }

  static read(reader) {
    let type = reader.getChar();
    let color = reader.getChar();
    let scale = reader.getChar();
    let intensity = reader.getChar();
    let daymode_min = reader.getChar();
    let pulse_mode = reader.getChar();
    let unk9 = reader.getChar();
    let xoffset = reader.getChar();
    let yoffset = reader.getChar();
    let unk11 = reader.getChar();
    let sub_light_mode = reader.getChar(); //noref
    let sub_light_scale = reader.getChar();
    let sub_light_intensity = reader.getChar();
    return new MapTileEffect(
      type,
      color,
      scale,
      intensity,
      daymode_min,
      pulse_mode,
      unk9,
      xoffset,
      yoffset,
      unk11,
      sub_light_mode,
      sub_light_scale,
      sub_light_intensity
    );
  }

  write(builder) {
    builder.addChar(this.type);
    builder.addChar(this.color);
    builder.addChar(this.scale);
    builder.addChar(this.intensity);
    builder.addChar(this.daymode_min);
    builder.addChar(this.pulse_mode);
    builder.addChar(this.unk9);
    builder.addChar(this.xoffset);
    builder.addChar(this.yoffset);
    builder.addChar(this.unk11);
    builder.addChar(this.sub_light_mode);
    builder.addChar(this.sub_light_scale);
    builder.addChar(this.sub_light_intensity);
  }
}

export class Prelude {
  constructor(id, unk1, unk2, secondary_id) {
    this.id = id;
    this.unk1 = unk1;
    this.unk2 = unk2;
    this.secondary_id = secondary_id;
  }

  static read(reader) {
    let id = reader.getShort();
    let unk1 = reader.getChar();
    let unk2 = reader.getChar();
    let secondary_id = reader.getShort();

    return new Prelude(id, unk1, unk2, secondary_id);
  }

  write(builder) {
    builder.addShort(this.id);
    builder.addChar(this.unk1);
    builder.addChar(this.unk2);
    builder.addShort(this.secondary_id);
  }
}

export class MapSign {
  constructor(title, message) {
    this.title = title;
    this.message = message;
  }

  static read(reader) {
    let length = reader.getShort() - 1;
    let data = bytesToString(reader.getFixedString(length));
    let titleLength = reader.getChar();
    let title = data.substr(0, titleLength);
    let message = data.substr(titleLength);

    return new MapSign(title, message);
  }

  write(builder) {
    let data = stringToBytes(this.title + this.message);
    builder.addShort(data.byteLength + 1);
    builder.addString(data);
    builder.addChar(windows1252.encode(this.title).length);
  }
}

export class MapTile {
  constructor() {
    this.gfx = new Array(13).fill(null);
    this.spec = null;
    this.warp = null;
    this.sign = null;
    this.yoffset = new Array(13).fill(0);
    this.effect = null;
  }
}

export class EMF {
  constructor() {
    this.mapID = 0;
    this.rid1 = 0;
    this.rid2 = 0;
    this.version = 0;
    this.subversion = 0;
    this.name = "";
    this.type = MapType.Normal;
    this.effect = MapEffect.None;
    this.musicID = 0;
    this.musicControl = MusicControl.InterruptIfDifferentPlayOnce;
    this.ambientSoundID = 0;
    this.width = 0;
    this.height = 0;
    this.fillTile = 0;
    this.mapAvailable = true;
    this.canScroll = true;
    this.relogX = 0;
    this.relogY = 0;

    this.npcs = [];
    this.legacyDoorKeys = [];
    this.specDoors = [];
    this.items = [];
    this.gathers = [];
    this.tiles = [];

    this.raisedLayerIndex = 1;
  }

  static new(width, height, name) {
    let emf = new EMF();

    emf.width = width;
    emf.height = height;
    emf.name = name;

    let tileCount = width * height;
    emf.tiles = new Array(tileCount);
    for (let i = 0; i < tileCount; ++i) {
      emf.tiles[i] = new MapTile();
    }

    return emf;
  }

  static read(id, reader) {
    this.raisedLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Raised"
    );

    let magic = windows1252.decode(reader.getFixedString(3));
    if (magic !== "EMF") {
      throw new Error("Invalid EMF file signature");
    }

    if (!EMF.looksLike04xFormat(reader)) {
      throw new Error("Pre-0.4.19 EMFs are unsupported");
    }

    let emf = new EMF();
    emf.mapID = id;
    console.log("Map ID: " + emf.mapID);
    emf.rid1 = reader.getShort();
    emf.rid2 = reader.getShort();
    emf.version = reader.getChar();
    emf.subversion = reader.getChar();

    console.log("Version: 0." + emf.version + "." + emf.subversion);

    // Versions less than 4.19 are unsupported, subversion specific GFX files are necessary in most cases
    // Adjust threshold if subversions exceed 30 :')
    if (
      emf.version < 4 ||
      (emf.version === 4 && emf.subversion < 19) ||
      (emf.version === 4 &&
        (emf.subversion === undefined || emf.subversion > 30))
    ) {
      throw new Error(
        "Pre-0.4.19 EMFs are unsupported. Detected version: 0." +
          emf.version +
          (emf.subversion > 30 ? ".?" : "." + emf.subversion)
      );
    }

    emf.width = reader.getChar() + 1;
    emf.height = reader.getChar() + 1;
    //emf.width += reader.getChar() + 1;
    //emf.height += reader.getChar() + 1;

    // Read 32 bytes for name_bytes
    const nameBytes = reader.getFixedString(32);
    // Decode the nameBytes to a string
    let name = decodeString(nameBytes);
    emf.name = name;

    console.log("Name: " + emf.name);

    emf.Unknown42 = reader.getChar();
    emf.fillTile = reader.getShort();
    emf.tileStep1GraphicID = reader.getShort();
    emf.tileStep1Mode = reader.getChar() + 1;
    emf.tileStep2GraphicID = reader.getShort();
    emf.tileStep2Mode = reader.getChar() + 1;
    emf.tileStep3GraphicID = reader.getShort();
    emf.tileStep3Mode = reader.getChar() + 1;
    emf.tileStep4GraphicID = reader.getShort();
    emf.tileStep4Mode = reader.getChar() + 1;
    emf.Unknown54 = reader.getChar();
    emf.blockMode = reader.getChar();
    emf.Unknown57 = reader.getChar();
    emf.ignoreFlag = reader.getChar();
    emf.fillFootsteps = reader.getChar();
    emf.fillSpecType = reader.getChar();
    emf.Unknown61 = reader.getChar();
    emf.channelBusy = reader.getChar();
    emf.channelFull = reader.getChar();
    emf.channelUnk = reader.getChar();
    emf.channelSwapDelay = reader.getChar();
    emf.Unknown66 = reader.getChar();
    emf.Unknown67 = reader.getChar();
    emf.weatherType = reader.getChar();
    emf.Unknown69 = reader.getChar();
    emf.dayMode = reader.getChar();
    emf.dayModeOverride = reader.getChar();
    emf.ambientLight = reader.getChar();
    emf.backgroundLight = reader.getChar();
    emf.combatType = reader.getChar();
    emf.hpDrainType = reader.getChar();
    emf.tpDrainType = reader.getChar();
    emf.musicVolume = reader.getChar();
    emf.musicID = reader.getChar();
    emf.musicExtra = reader.getChar();
    emf.ambientSoundID = reader.getChar();
    emf.ambientSoundID2 = reader.getChar();
    emf.ambientSoundID3 = reader.getChar();
    emf.minimap = reader.getChar();
    emf.scrollLock = reader.getChar();
    emf.Unknown90 = reader.getChar();
    emf.Unknown91 = reader.getChar();
    emf.respawnX = reader.getChar();
    emf.respawnY = reader.getChar();
    emf.Unknown94 = reader.getChar();
    emf.Unknown95 = reader.getChar();
    emf.Unknown96 = reader.getChar();
    emf.Unknown97 = reader.getChar();
    emf.Unknown98 = reader.getChar();
    emf.Unknown99 = reader.getChar();

    emf.Unknown100 = reader.getChar();
    emf.Unknown101 = reader.getChar();
    emf.Unknown102 = reader.getChar();
    emf.Unknown103 = reader.getChar();

    console.log(reader.position);
    console.log("Width: " + emf.width);
    console.log("Height: " + emf.height);

    let tileCount = emf.width * emf.height;
    emf.tiles = new Array(tileCount);
    for (let i = 0; i < tileCount; ++i) {
      emf.tiles[i] = new MapTile();
      emf.tiles[i].gfx[0] = emf.fillTile;
    }

    let npcCount = reader.getChar();
    console.log("Total NPCs: " + npcCount);
    for (let i = 0; i < npcCount; ++i) {
      emf.npcs.push(MapNPC.read(reader));
    }

    let unk = reader.getChar();
    let numSpecDoor = reader.getChar();
    console.log("Total Spec Doors: " + numSpecDoor);
    for (let i = 0; i < numSpecDoor; ++i) {
      emf.specDoors.push(MapSpecDoor.read(reader));
    }
    let unk2 = reader.getChar();
    let unk3 = reader.getChar();

    let itemCount = reader.getChar();
    console.log("Total Items: " + itemCount);
    for (let i = 0; i < itemCount; ++i) {
      emf.items.push(MapItem.read(reader));
    }

    let unkCount = reader.getChar();
    console.log("Total Unk: " + unkCount);
    for (let i = 0; i < unkCount; ++i) {
      reader.skip(4);
    }

    let gathers = reader.getChar();
    console.log("Total Gathers: " + gathers);
    for (let i = 0; i < gathers; ++i) {
      emf.gathers.push(MapGather.read(reader));
    }

    let unkCount2 = reader.getChar();
    console.log("Total Unk2: " + unkCount2);
    for (let i = 0; i < unkCount2; ++i) {
      reader.skip(4);
    }

    {
      let numOfSpecRows = reader.getChar();
      let debug = 0;
      for (let i = 0; i < numOfSpecRows; ++i) {
        let y = reader.getChar();
        let tiles = reader.getChar();

        for (let ii = 0; ii < tiles; ++ii) {
          let x = reader.getChar();
          let tileSpec = reader.getChar();
          debug += 1;
          emf.getTile(x, y).spec = tileSpec;
        }
      }
      console.log("Total Spec Tiles: " + debug);
    }

    {
      let rows = reader.getChar();
      let debug = 0;
      for (let i = 0; i < rows; ++i) {
        let y = reader.getChar();
        let tiles = reader.getChar();
        for (let ii = 0; ii < tiles; ++ii) {
          let x = reader.getChar();
          let warp = MapWarp.read(reader);

          debug += 1;
          emf.getTile(x, y).warp = warp;
        }
      }
      console.log("Total Warps: " + debug);
    }

    for (let layer = 0; layer < 13; ++layer) {
      let rows = reader.getChar();
      let debug = 0;
      for (let i = 0; i < rows; ++i) {
        let y = reader.getChar();
        let tiles = reader.getChar();
        for (let ii = 0; ii < tiles; ++ii) {
          let x = reader.getChar();
          let graphic = reader.getShort();

          debug += 1;
          if (layer == this.raisedLayerIndex) {
            emf.getTile(x, y).yoffset[layer] = reader.getChar();
            console.log("Y Offset: " + emf.getTile(x, y).yoffset[layer]);
          }

          if (layer > 0 && graphic === 0) {
            continue;
          }

          if (x < emf.width && y < emf.height) {
            emf.getTile(x, y).gfx[layer] = graphic;
          }
        }
      }
      console.log("Total Layer " + layer + " Tiles: " + debug);
    }

    let numFx = reader.getChar();
    let debug = 0;
    for (let i = 0; i < numFx; ++i) {
      let y = reader.getChar();
      let tiles = reader.getChar();
      for (let ii = 0; ii < tiles; ++ii) {
        let x = reader.getChar();
        let effect = MapTileEffect.read(reader);
        debug += 1;
        emf.getTile(x, y).effect = effect;
      }
    }
    console.log("Total Tile Effects: " + debug);

    if (reader.remaining > 0) {
      let preludes = reader.getChar();
      for (let i = 0; i < preludes; ++i) {
        let x = reader.getChar();
        let y = reader.getChar();

        if (x < emf.width && y < emf.height) {
          emf.getTile(x, y).prelude = Prelude.read(reader);
        }
      }
    }

    if (reader.remaining > 0) {
      let signs = reader.getChar();
      for (let i = 0; i < signs; ++i) {
        let x = reader.getChar();
        let y = reader.getChar();

        if (x < emf.width && y < emf.height) {
          emf.getTile(x, y).sign = MapSign.read(reader);
        }
      }
    }

    return emf;
  }

  write(builder) {
    this.raisedLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Raised"
    );

    builder.addString(new Uint8Array([0x45, 0x4d, 0x46]));
    builder.addHash();
    builder.addChar(this.version);
    builder.addChar(this.subversion);
    builder.addChar(this.width - 1);
    builder.addChar(this.height - 1);
    builder.addString(encodeStringName(this.name));

    builder.addChar(this.Unknown42);
    builder.addShort(this.fillTile);
    builder.addShort(this.tileStep1GraphicID);
    builder.addChar(this.tileStep1Mode);
    builder.addShort(this.tileStep2GraphicID);
    builder.addChar(this.tileStep2Mode);
    builder.addShort(this.tileStep3GraphicID);
    builder.addChar(this.tileStep3Mode);
    builder.addShort(this.tileStep4GraphicID);
    builder.addChar(this.tileStep4Mode);
    builder.addChar(this.Unknown54);
    builder.addChar(this.blockMode);
    builder.addChar(this.Unknown57);
    builder.addChar(this.ignoreFlag);
    builder.addChar(this.fillFootsteps);
    builder.addChar(this.fillSpecType);
    builder.addChar(this.Unknown61);
    builder.addChar(this.channelBusy);
    builder.addChar(this.channelFull);
    builder.addChar(this.channelUnk);
    builder.addChar(this.channelSwapDelay);
    builder.addChar(this.Unknown66);
    builder.addChar(this.Unknown67);
    builder.addChar(this.weatherType);
    builder.addChar(this.Unknown69);
    builder.addChar(this.dayMode);
    builder.addChar(this.dayModeOverride);
    builder.addChar(this.ambientLight);
    builder.addChar(this.backgroundLight);
    builder.addChar(this.combatType);
    builder.addChar(this.hpDrainType);
    builder.addChar(this.tpDrainType);
    builder.addChar(this.musicVolume);
    builder.addChar(this.musicID);
    builder.addChar(this.musicExtra);
    builder.addChar(this.ambientSoundID);
    builder.addChar(this.ambientSoundID2);
    builder.addChar(this.ambientSoundID3);
    builder.addChar(this.minimap);
    builder.addChar(this.scrollLock);
    builder.addChar(this.Unknown90);
    builder.addChar(this.Unknown91);
    builder.addChar(this.respawnX);
    builder.addChar(this.respawnY);
    builder.addChar(this.Unknown94);
    builder.addChar(this.Unknown95);
    builder.addChar(this.Unknown96);
    builder.addChar(this.Unknown97);
    builder.addChar(this.Unknown98);
    builder.addChar(this.Unknown99);

    builder.addChar(this.Unknown100);
    builder.addChar(this.Unknown101);
    builder.addChar(this.Unknown102);
    builder.addChar(this.Unknown103);

    builder.addChar(this.npcs.length);
    for (let npc of this.npcs) {
      npc.write(builder);
    }

    builder.addChar(0);
    builder.addChar(this.specDoors.length);
    for (let specDoor of this.specDoors) {
      specDoor.write(builder);
    }

    builder.addChar(0); //unk2
    builder.addChar(0); //unk3

    builder.addChar(this.items.length);
    for (let item of this.items) {
      item.write(builder);
    }

    builder.addChar(0); //unkCount

    builder.addChar(this.gathers.length);
    for (let resource of this.gathers) {
      resource.write(builder);
    }

    builder.addChar(0); //unkCount2

    let specRows = [];
    let warpRows = [];
    let gfxRows = [];
    let signRows = [];
    let fxRows = [];
    let preludeRows = [];

    // Initialize gfxRows for each layer
    for (let i = 0; i < 13; ++i) {
      gfxRows.push([]);
    }

    for (let y = 0; y < this.height; ++y) {
      let specRow = new TileRow(y);
      let warpRow = new TileRow(y);
      let gfxRow = [];
      let signRow = new TileRow(y);
      let fxRow = new TileRow(y);
      let preludeRow = new TileRow(y);

      // Initialize gfxRow for each layer
      for (let i = 0; i < 13; ++i) {
        gfxRow.push(new TileRow(y));
      }

      for (let x = 0; x < this.width; ++x) {
        let tile = this.getTile(x, y);

        // Handle spec tiles
        if (tile.spec !== null) {
          specRow.add(x);
          specRow.add(tile.spec);
        }

        // Handle warp tiles
        if (tile.warp !== null) {
          warpRow.add(x);
        }

        // Handle gfx layers, excluding fill tile on the ground layer
        for (let layer = 0; layer < 13; ++layer) {
          let graphicID = tile.gfx[layer];
          let isFillTile = layer === 0 && graphicID === this.fillTile;
          if (graphicID !== null && !isFillTile) {
            gfxRow[layer].add(x);
            gfxRow[layer].add(graphicID);
          }
        }

        // Handle signs
        if (tile.sign !== null) {
          signRow.add(x);
        }

        // Handle tile effects
        if (tile.effect !== null) {
          fxRow.add(x);
        }

        // Handle preludes
        if (tile.prelude !== undefined) {
          preludeRow.add(x);
        }
      }

      if (!specRow.empty) {
        specRows.push(specRow);
      }

      if (!warpRow.empty) {
        warpRows.push(warpRow);
      }

      for (let layer = 0; layer < 13; ++layer) {
        if (!gfxRow[layer].empty) {
          gfxRows[layer].push(gfxRow[layer]);
        }
      }

      if (!signRow.empty) {
        signRows.push(signRow);
      }

      if (!fxRow.empty) {
        fxRows.push(fxRow);
      }

      if (!preludeRow.empty) {
        preludeRows.push(preludeRow);
      }
    }

    // Write spec rows
    builder.addChar(specRows.length);
    for (let row of specRows) {
      let y = row.y;
      builder.addChar(y);
      builder.addChar(row.length / 2);
      for (let i = 0; i < row.length; i += 2) {
        builder.addChar(row.tiles[i]);
        builder.addChar(row.tiles[i + 1]);
      }
    }

    // Write warp rows
    builder.addChar(warpRows.length);
    for (let row of warpRows) {
      let y = row.y;
      builder.addChar(y);
      builder.addChar(row.length);
      for (let x of row.tiles) {
        builder.addChar(x);
        let warp = this.getTile(x, y).warp;
        warp.write(builder);
      }
    }

    // Write gfx layers
    for (let layer = 0; layer < 13; ++layer) {
      let layerRows = gfxRows[layer];
      builder.addChar(layerRows.length);
      for (let row of layerRows) {
        let y = row.y;
        builder.addChar(y);
        builder.addChar(row.length / 2);
        for (let i = 0; i < row.length; i += 2) {
          builder.addChar(row.tiles[i]);
          builder.addShort(row.tiles[i + 1]);
          // Handle y-offset for raised layers
          if (layer === this.raisedLayerIndex) {
            builder.addChar(this.getTile(row.tiles[i], row.y).yoffset[layer]);
          }
        }
      }
    }

    // Write tile effects (fxRows)
    builder.addChar(fxRows.length);
    for (let row of fxRows) {
      let y = row.y;
      builder.addChar(y);
      builder.addChar(row.length);
      for (let x of row.tiles) {
        builder.addChar(x);
        let tile = this.getTile(x, y);
        console.log("Writing tile effect at " + x + ", " + y);
        tile.effect.write(builder);
      }
    }

    // Write preludes (preludeRows)
    builder.addChar(preludeRows.length);
    for (let row of preludeRows) {
      for (let x of row.tiles) {
        let tile = this.getTile(x, row.y);
        builder.addChar(x);
        builder.addChar(row.y);
        tile.prelude.write(builder);
      }
    }

    // Write signs
    builder.addChar(signRows.length);
    for (let row of signRows) {
      let y = row.y;
      for (let x of row.tiles) {
        builder.addChar(x);
        builder.addChar(y);
        let sign = this.getTile(x, y).sign;
        sign.write(builder);
      }
    }
  }

  static looksLike04xFormat(reader) {
    let score = 0.0;
    let originalPosition = reader.position;
    try {
      // In the classic format, 0x1f is the `type` char field.
      // An out-of-bounds value indicates this is a byte in a 0.4.x map name.
      //
      // Many classic EMFs in the wild have been observed to have out-of-bounds values,
      // presumably due to broken serializers in early EO community developer tools.
      reader.seek(0x1f);
      const type = reader.getByte() - 1;
      if (type > MapType.PK) {
        score += 0.2;
      }

      // In the classic format, 0x20 is the `effect` char field.
      // An out-of-bounds value indicates this is a byte in a 0.4.x map name.
      const effect = reader.getByte() - 1;
      if (effect > MapEffect.Quake4) {
        score += 0.4;
      }

      // In the classic format, 0x22 is the `musicControl` char field.
      // An out-of-bounds value indicates this is a byte in a 0.4.x map name.
      reader.seek(0x22);
      const musicControlField = reader.getByte() - 1;
      if (musicControlField > MusicControl.InterruptPlayNothing) {
        score += 0.4;
      }

      // In the classic format, 0x24 is the second byte of the `ambientSoundId` char field.
      // It's extremely rare for this value to exceed the size of a short, so the second byte is
      // practically always 0xFE.
      // A non-0xFE value indicates this is a byte in a 0.4.x map name.
      //
      // Some classic EMFs in the wild have been observed to have a value of 0x01,
      // presumably due to broken serializers in early EO community developer tools.
      reader.seek(0x24);
      const secondByteOfAmbientSoundID = reader.getByte();
      if (secondByteOfAmbientSoundID != 0xfe) {
        score += secondByteOfAmbientSoundID == 0x01 ? 0.5 : 0.9;
      }

      // In the classic format, 0x26 is the `height` char field.
      // A value > 252 indicates this is a padding byte in a 0.4.x map name.
      reader.seek(0x26);
      const heightField = reader.getByte();
      if (heightField > 252) {
        score += 1.0;
      }

      // In the classic format, 0x2d is a char field which is always zero.
      //
      // Some classic EMFs in the wild have been observed to have non-standard (char) values,
      // presumably due to broken serializers in early EO community developer tools.
      reader.seek(0x2d);
      const zeroField = reader.getByte();
      if (zeroField != 1) {
        score += zeroField > 252 ? 1.0 : 0.5;
      }
    } finally {
      reader.seek(originalPosition);
    }

    return score >= 1.0;
  }

  getTile(x, y) {
    return this.tiles[y * this.width + x];
  }
}

class TileRow {
  constructor(y) {
    this.y = y;
    this.tiles = [];
  }

  add(x) {
    this.tiles.push(x);
  }

  get length() {
    return this.tiles.length;
  }

  get empty() {
    return this.tiles.length === 0;
  }
}
