import { layerInfo } from "../data/layer-info";
export class LayerVisibilityState {
  constructor() {
    this._flags = [];
    this._selectedLayer = 0;
    const excludedLayers = ["Grid", "Special", "Effects", "Sign", "NPC", "Item"];

    layerInfo.forEach((layer) => {
      this._flags.push(
        (!layer.isEntity || (!layer.isPaletteLayer && layer.isEntity)) && !excludedLayers.includes(layer.name)
      );
    });
  }

  copy() {
    let copy = new LayerVisibilityState();
    copy._flags = this._flags;
    copy._selectedLayer = this._selectedLayer;
    return copy;
  }

  withFlagToggled(flag) {
    let copy = this.copy();
    copy._flags[flag] = !copy._flags[flag];
    return copy;
  }

  withSelectedLayer(layer) {
    let copy = this.copy();
    copy._selectedLayer = layer;
    return copy;
  }

  isFlagActive(flag) {
    return this._flags[flag] || this.isFlagOverridden(flag);
  }

  isFlagOverridden(flag) {
    return flag === this._selectedLayer;
  }

  isLayerVisible(layer) {
    const visibilityFlag = layerInfo[layer].visibilityFlag;

    if (visibilityFlag !== undefined) {
      return this._flags[visibilityFlag];
    } else {
      return this._flags[layer] || this._selectedLayer === layer;
    }
  }
}
