import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("eomap-slider")
export class Slider extends LitElement {
  static styles = css`
    :host {
      display: block;
      width: 100%;
    }

    .slider-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .slider {
      width: 100%;
      -webkit-appearance: none;
      height: 8px;
      background: #444;
      border-radius: 5px;
      outline: none;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ddd;
      cursor: pointer;
      box-shadow: 0 0 2px #000;
    }

    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ddd;
      cursor: pointer;
      box-shadow: 0 0 2px #000;
    }

    .slider-value {
      font-size: 14px;
      color: #ddd;
      background-color: #222;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .slider:hover::-webkit-slider-thumb {
      background: #fff;
    }

    .slider:hover::-moz-range-thumb {
      background: #fff;
    }
  `;

  @property({ type: Number }) value = 50;
  @property({ type: Number }) min = 0;
  @property({ type: Number }) max = 100;

  handleInput(event) {
    this.value = event.target.value;
    this.dispatchEvent(
      new CustomEvent("slider-change", {
        detail: { value: this.value },
        bubbles: true,
        composed: true,
      })
    );
  }

  render() {
    return html`
      <div class="slider-container">
        <input
          class="slider"
          type="range"
          .value="${this.value}"
          .min="${this.min}"
          .max="${this.max}"
          @input="${this.handleInput}"
        />
        <span class="slider-value">${this.value}</span>
      </div>
    `;
  }
}
