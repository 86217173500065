import {
  PaletteLayerResourceEntry,
  PaletteLayerSpecEntry,
  PaletteLayerBlackTileEntry,
} from "../gameobjects/palette-layer";
import { TextureCache } from "../gfx/texture-cache";
import { layerInfo } from "../data/layer-info";

class LayerPreload {
  static PRELOAD_PER_FRAME = 5;

  constructor(layer) {
    this.layer = layer;
    this.preloadEntries = Array.from(this.layer.entries.values());
  }

  update() {
    let pending = this.layer.scene.textureCache.pending;
    let amount = 0;

    for (let resource of this.preloadEntries) {
      if (pending.length >= LayerPreload.PRELOAD_PER_FRAME) {
        break;
      }
      resource.preload();
      ++amount;
    }

    this.preloadEntries.splice(0, amount);
  }

  get finished() {
    return this.preloadEntries.length === 0;
  }
}

export class PaletteScene extends Phaser.Scene {
  static FILE_BY_LAYER = layerInfo
    .filter((layer) => layer.isPaletteLayer)
    .map((layer) => layer.fileID);

  constructor() {
    super("palette");
    this.textureCache = null;
    this.selectedLayer = null;
    this.layers = [];
    this.preloads = [];
    this.lastResize = performance.now();
    this.dirtySelectedLayer = true;
    this.dirtySelectedEntry = true;
    this.tileLayerFileID = layerInfo.find(
      (layer) => layer.name === "Tile"
    ).fileID;
    this.masksLayerFileID = layerInfo.find(
      (layer) => layer.name === "Mask"
    ).fileID;
    this.downWallsLayerFileID = layerInfo.find(
      (layer) => layer.name === "Down Wall"
    ).fileID;
  }

  create() {
    this.textureCache = new TextureCache(
      this,
      this.data.values.gfxLoader,
      2048,
      2048
    );

    this.layers = this.createLayers();
    this.preloads = this.layers.map((layer) => new LayerPreload(layer));

    this.data.events.on(
      "changedata-selectedLayer",
      (_parent, value, _previousValue) => {
        this.selectLayer(value);
      }
    );

    this.data.events.on(
      "changedata-eyedrop",
      (_parent, value, _previousValue) => {
        this.selectEntry(value.drawID);
        let resource = this.selectedLayer.selectedEntry;
        if (resource) {
          this.selectedLayer.scroll = resource.y;
          this.emitScrollChangedEvent();
        }
        this.updateSelectedDrawID();
      }
    );

    this.data.events.on(
      "changedata-contentScroll",
      (_parent, value, _previousValue) => {
        this.cameras.main.scrollY = value;
        this.selectedLayer.scroll = value;
      }
    );

    this.input.on("pointerup", (pointer) => {
      if (pointer.leftButtonReleased() && pointer.getDistance() < 16) {
        let entry = this.selectedLayer.getEntryAtPosition(
          pointer.x,
          pointer.y + this.cameras.main.scrollY
        );
        if (entry) {
          this.selectEntry(entry.id);
          this.updateSelectedDrawID();
        }
      }
    });

    this.scale.on("resize", this.resize, this);
    this.resize();

    this.selectLayer(this.data.values.selectedLayer);
  }

  createLayers() {
    let result = [];

    let i = 0;
    for (let fileID of PaletteScene.FILE_BY_LAYER) {
      if (fileID === undefined) {
        ++i;
        continue;
      }
      result.push(this.createResourceLayer(fileID, i));
    }

    result.push(this.createSpecLayer());

    return result;
  }

  createResourceLayer(fileID, layerID) {
    let layer = this.add.paletteLayer(this);
    let gfxLoader = this.data.values.gfxLoader;
    let resourceIDs = gfxLoader.resourceIDs(fileID);

    if (fileID === this.tileLayerFileID) {
      layer.addEntry(new PaletteLayerBlackTileEntry(this.textureCache));
    }

    for (let resourceID of resourceIDs) {
      if (resourceID < 101) {
        continue;
      }

      let info = gfxLoader.resourceInfo(fileID, resourceID);
      let width = info.width;
      let height = info.height;

      if (fileID === this.tileLayerFileID || fileID === this.masksLayerFileID) {
        width = 64;
        height = 32;
      }

      if (fileID === this.downWallsLayerFileID && width >= 32 * 4) {
        width = Math.floor(width / 4);
      }

      let resource = new PaletteLayerResourceEntry(
        this.textureCache,
        width,
        height,
        fileID,
        resourceID,
        layerID
      );

      layer.addEntry(resource);
    }

    return layer;
  }

  createSpecLayer() {
    let layer = this.add.paletteLayer(this);

    for (let tileSpec = 0; tileSpec < 37; ++tileSpec) {
      let spec = new PaletteLayerSpecEntry(this.textureCache, tileSpec);
      layer.addEntry(spec);
    }

    return layer;
  }

  selectLayer(layer) {
    if (this.selectedLayer) {
      this.syncFileScroll();
      this.selectedLayer.visible = false;
    }

    this.selectedLayer = this.layers[layer];
    this.selectedLayer.visible = true;
    this.dirtySelectedLayer = true;

    if (this.selectedLayer.dirtyLayout) {
      this.selectedLayer.layout();
    }
    this.prioritizePreloads();
    this.updateSelectedDrawID();
    this.emitContentHeightChangedEvent();
    this.emitScrollChangedEvent();
  }

  selectEntry(entryID) {
    this.selectedLayer.selectEntry(entryID);
    this.dirtySelectedEntry = true;
  }

  syncFileScroll() {
    let layerID = this.layers.indexOf(this.selectedLayer);
    if (layerID === -1) {
      return;
    }

    let fileID = PaletteScene.FILE_BY_LAYER[layerID];
    if (fileID === undefined) {
      return;
    }

    for (let layer of this.getLayersByFile(fileID)) {
      if (layer === undefined) {
        continue;
      }
      layer.scroll = this.selectedLayer.scroll;
    }
  }

  getLayersByFile(fileID) {
    const layerIndices = layerInfo
      .map((layer, index) => ({
        index,
        fileID: layer.fileID,
        isEntity: layer.isEntity,
      }))
      .filter(
        (layer) =>
          (!layer.isEntity || (!layer.isPaletteLayer && layer.isEntity)) &&
          layer.fileID === fileID
      )
      .map((layer) => layer.index);

    const validLayers = layerIndices
      .filter((index) => index >= 0 && index < this.layers.length)
      .map((index) => this.layers[index]);

    if (layerIndices.length !== validLayers.length) {
      console.error(
        "Some layer indices did not correspond to valid layers:",
        layerIndices
      );
    }

    return validLayers;
  }

  update(_time, _delta) {
    this.selectedLayer.update(_time, _delta);

    let dirtyLayout =
      this.selectedLayer.dirtyLayout && this.canDoResizeLayout();

    this.render.shouldRender =
      dirtyLayout ||
      this.dirtySelectedLayer ||
      this.dirtySelectedEntry ||
      this.cameras.main.dirty ||
      this.selectedLayer.dirtyAnimationFrame;

    this.dirtySelectedLayer = false;
    this.dirtySelectedEntry = false;

    if (dirtyLayout) {
      this.selectedLayer.layout();
      this.emitContentHeightChangedEvent();
    }

    if (this.preloads.length > 0) {
      let preload = this.preloads[0];
      preload.update();
      if (preload.finished) {
        this.preloads.shift();
      }
    }

    this.textureCache.update();
  }

  canDoResizeLayout() {
    return this.lastResize < performance.now() - 100;
  }

  resize(gameSize, _baseSize, _displaySize, _resolution) {
    let width;
    let height;

    if (gameSize === undefined) {
      width = this.sys.scale.width;
      height = this.sys.scale.height;
    } else {
      width = gameSize.width;
      height = gameSize.height;
    }

    for (let layer of this.layers) {
      layer.width = width;
    }

    this.cameras.main.setSize(width, height);
    this.lastResize = performance.now();
  }

  prioritizePreloads() {
    let preloads = this.preloads;
    for (let preload of preloads) {
      if (preload.layer === this.selectedLayer) {
        Phaser.Utils.Array.MoveTo(preloads, preload, 0);
        break;
      }
    }
  }

  emitContentHeightChangedEvent() {
    this.events.emit("contentHeight-changed", this.selectedLayer.height);
  }

  emitScrollChangedEvent() {
    this.events.emit(
      "scroll-changed",
      this.selectedLayer.scroll,
      this.data.values.contentScroll
    );
  }

  updateSelectedDrawID() {
    let id = null;
    if (this.selectedLayer.selectedEntry) {
      id = this.selectedLayer.selectedEntry.id;
    }
    this.data.set("selectedDrawID", id);
  }
}
