import { html, LitElement } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";

import "@spectrum-web-components/field-group/sp-field-group.js";
import "@spectrum-web-components/field-label/sp-field-label.js";

import "./modal";
import "./textfield";
import "./number-field";
import "./picker";
import "./menu-item";

import { CHAR_MAX, SHORT_MAX } from "../data/eo-numeric-limits";

@customElement("eomap-entity-warp")
export class EntityWarp extends LitElement {
  @query("#warp_type", true)
  warp_type;

  @query("#map", true)
  map;

  @query("#x", true)
  x;

  @query("#y", true)
  y;

  @query("#quest_id", true)
  quest_id;

  @query("#key", true)
  key;

  @property({ type: Boolean, reflect: true })
  open = false;

  @property({ type: String, reflect: true })
  headline;

  render() {
    return html`
      <eomap-modal
        confirm-label="Save"
        cancel-label="Cancel"
        .headline=${this.headline}
        .open=${this.open}
        @confirm=${this.confirm}
        @cancel=${this.cancel}
        @close=${this.close}
      >
        <style>
          .field-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 10px;
          }
          .field-container {
            display: flex;
            flex-direction: column;
            margin: 5px;
          }
          sp-field-group {
            justify-content: center;
          }
        </style>
        <sp-field-group>
          <!-- First row: Map, X, Y -->
          <div class="field-row">
            <div class="field-container">
              <sp-field-label for="map">Map</sp-field-label>
              <eomap-number-field
                id="map"
                max="${SHORT_MAX - 1}"
              ></eomap-number-field>
            </div>
            <div class="field-container">
              <sp-field-label for="x">X</sp-field-label>
              <eomap-number-field
                id="x"
                max="${CHAR_MAX - 1}"
              ></eomap-number-field>
            </div>
            <div class="field-container">
              <sp-field-label for="y">Y</sp-field-label>
              <eomap-number-field
                id="y"
                max="${CHAR_MAX - 1}"
              ></eomap-number-field>
            </div>
          </div>
        </sp-field-group>
        <sp-field-group>
          <div class="field-container">
            <sp-field-label for="warp_type">Warp Type</sp-field-label>
            <eomap-picker id="warp_type">
              ${[
                { label: "No Door", value: "0" },
                { label: "Door", value: "1" },
                { label: "Locked Quest", value: "2" },
                { label: "Unknown", value: "3" },
                { label: "Transport", value: "4" },
              ].map(
                (option) => html`
                  <eomap-menu-item
                    label="${option.label}"
                    value="${option.value}"
                  >
                    <span>${option.label}</span>
                  </eomap-menu-item>
                `
              )}
            </eomap-picker>
          </div>
        </sp-field-group>
        <sp-field-group>
          <div class="field-container">
            <sp-field-label for="key">Key</sp-field-label>
            <eomap-number-field
              id="key"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="quest_id">Quest ID</sp-field-label>
            <eomap-number-field
              id="quest_id"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
        </sp-field-group>
      </eomap-modal>
    `;
  }

  reset() {
    // Reset the map and position fields
    if (this.map) {
      this.map.value = undefined;
      this.map.invalid = false;
    }
    if (this.x) {
      this.x.value = undefined;
      this.x.invalid = false;
    }
    if (this.y) {
      this.y.value = undefined;
      this.y.invalid = false;
    }

    // Reset warp type to default
    this.warp_type.value = "0";

    // Reset additional fields
    if (this.quest_id) {
      this.quest_id.value = 0;
      this.quest_id.invalid = false;
    }

    if (this.key) {
      this.key.value = 0;
      this.key.invalid = false;
    }
  }

  populate(warp) {
    this.reset(); // Reset first

    if (this.map) {
      this.map.value = warp.map;
    }
    if (this.x) {
      this.x.value = warp.x;
    }
    if (this.y) {
      this.y.value = warp.y;
    }

    // Set warpType state
    this.warp_type.value = String(warp.spec);

    // Force update to render additional fields
    this.requestUpdate().then(() => {
      // Populate quest_id and key based on warp type
      if (warp.spec === 2 && this.quest_id) {
        this.quest_id.value = warp.quest_id;
      }

      if (warp.spec === 1 && this.key) {
        this.key.value = Math.max(0, warp.door - 1);
      }
    });
  }

  validateRequired(field) {
    field.invalid = isNaN(field.value);
  }

  validateFields() {
    this.validateRequired(this.map);
    this.validateRequired(this.x);
    this.validateRequired(this.y);
    console.log(this.warp_type.value);

    if (parseInt(this.warp_type.value) === "2") {
      // Locked Quest
      this.validateRequired(this.quest_id);
    }

    if (parseInt(this.warp_type.value) === "1") {
      // Door
      this.validateRequired(this.key);
    }

    return (
      !this.map.invalid &&
      !this.x.invalid &&
      !this.y.invalid &&
      (parseInt(this.warp_type.value) !== "2" || !this.quest_id.invalid) &&
      (parseInt(this.warp_type.value) !== "1" || !this.key.invalid)
    );
  }

  confirm(_event) {
    if (this.validateFields()) {
      const warpData = {
        map: this.map.value, // Always required
        x: this.x.value, // Always required
        y: this.y.value, // Always required
        spec: parseInt(this.warp_type.value), // Warp type (spec) as a number
        quest_id: 0, // Default as 0 unless specified
        door: 0, // Default door to 0 unless specified
      };

      // Set quest_id if the selected spec is 'Locked Quest'
      if (parseInt(this.warp_type.value) === "2") {
        warpData.quest_id = this.quest_id.value;
      }

      // Set door if the selected spec is 'Door'
      if (parseInt(this.warp_type.value) === "1") {
        warpData.door = this.key.value + 1;
      }

      console.log(warpData); // Log the warp data

      // Dispatch the event with the warp data
      this.open = false;
      this.dispatchEvent(
        new CustomEvent("save", {
          detail: warpData,
        })
      );
    }
  }

  cancel(_event) {
    this.open = false;
  }

  close(_event) {
    this.open = false;
    this.dispatchEvent(new CustomEvent("close"));
  }
}
