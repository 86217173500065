import { TDG, RDG } from "../gameobjects/eomap";

export const layerInfo = [
  {
    name: "Tile",
    fileID: 4,
    xoff: 0,
    yoff: 0,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: -3.0 + TDG * 1,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 2.75,
  },
  {
    name: "Raised",
    fileID: 5,
    xoff: -1,
    yoff: 0,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 0.0 + TDG * 2,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 2.75,
  },
  {
    name: "Object",
    fileID: 6,
    xoff: -2,
    yoff: -2,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 0.0 + TDG * 2,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 2.0,
    animationDimensionThreshold: 1.05,
  },
  {
    name: "Mask",
    fileID: 7,
    xoff: -2,
    yoff: -2,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 0.0 + TDG * 3,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "Down Wall",
    fileID: 8,
    xoff: 0,
    yoff: -1,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 0.0 + TDG * 4,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 1.25,
  },
  {
    name: "Right Wall",
    fileID: 8,
    xoff: 32,
    yoff: -1,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: -RDG + TDG * 5,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 1.25,
  },
  {
    name: "Wall Object",
    fileID: 6,
    xoff: 26,
    yoff: -38,
    alpha: 1.0,
    rightWallxoff: 46,
    centered: true,
    bottomOrigin: false,
    depth: 1.0 + TDG * 4,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 1.25,
  },
  {
    name: "Roof",
    fileID: 9,
    xoff: 0,
    yoff: -64,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 0.0 + TDG * 6,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "Desk",
    fileID: 4,
    xoff: 0,
    yoff: -32,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: 0.0 + TDG * 1,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "Desk Object",
    fileID: 6,
    xoff: -2,
    yoff: -34,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 0.0 + TDG * 2,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 1.75,
  },
  {
    name: "Blends",
    fileID: 24,
    xoff: -24,
    yoff: -12,
    alpha: 0.2,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: -1.0 + TDG * 1,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "Edge Mask",
    fileID: 7,
    xoff: -2,
    yoff: -2,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 1.0 + TDG * 1,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "High Wall Objects",
    fileID: 6,
    xoff: -2,
    yoff: -2,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: true,
    bottomOrigin: true,
    depth: 5.0 + TDG * 2,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: true,
    animationWidthThreshold: 1.5,
    animationDimensionThreshold: 1.05,
  },
  {
    name: "Special",
    xoff: 0,
    yoff: 0,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: 3.0 + TDG * 1,
    isPaletteLayer: true,
    isEntity: false,
    isAnimated: false,
  },
  {
    name: "Effects",
    xoff: -96,
    yoff: -112,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: 0.0 + TDG * 2,
    isPaletteLayer: false,
    isEntity: true,
    isAnimated: false,
  },
  {
    name: "Warp",
    xoff: -1,
    yoff: 0,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 4.0 + TDG * 1,
    isPaletteLayer: false,
    isEntity: true,
    isAnimated: false,
  },
  {
    name: "Sign",
    xoff: -1,
    yoff: 0,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 4.0 + TDG * 2,
    isPaletteLayer: false,
    isEntity: true,
    isAnimated: false,
  },
  {
    name: "Item",
    xoff: -1,
    yoff: 0,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 4.0 + TDG * 3,
    isPaletteLayer: false,
    isEntity: true,
    isAnimated: false,
  },
  {
    name: "NPC",
    xoff: -1,
    yoff: 0,
    alpha: 0.5,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: true,
    depth: 4.0 + TDG * 4,
    isPaletteLayer: false,
    isEntity: true,
    isAnimated: false,
  },
  {
    name: "Grid",
    xoff: 0,
    yoff: 0,
    alpha: 1.0,
    rightWallxoff: 0,
    centered: false,
    bottomOrigin: false,
    depth: 5.0 + TDG * 1,
    isPaletteLayer: false,
    isEntity: false,
    isAnimated: false,
  },
];
