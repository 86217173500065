import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import "@spectrum-web-components/field-label/sp-field-label.js";

import "./modal";
import "./number-field";

import { CHAR_MAX, SHORT_MAX } from "../data/eo-numeric-limits";

@customElement("eomap-entity-gather")
export class EntityGather extends LitElement {
  @query("#type", true) type;
  @query("#hit_count", true) hit_count;
  @query("#unk3", true) unk3;
  @query("#blend_id", true) blend_id;
  @query("#tile_id", true) tile_id;
  @query("#graphic_id", true) graphic_id;
  @query("#item_id", true) item_id;
  @query("#time", true) time;
  @query("#unk11", true) unk11;
  @query("#max_amount", true) max_amount;

  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: String, reflect: true }) headline;

  render() {
    return html`
      <eomap-modal
        confirm-label="Save"
        cancel-label="Cancel"
        .headline=${this.headline}
        .open=${this.open}
        @confirm=${this.confirm}
        @cancel=${this.cancel}
        @close=${this.close}
      >
        <style>
          .field-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 10px;
          }
          .field-container {
            display: flex;
            flex-direction: column;
            margin: 5px;
          }
        </style>
        <div class="field-row">
          <div class="field-container">
            <sp-field-label for="type">Type</sp-field-label>
            <eomap-number-field
              id="type"
              max="${CHAR_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="hit_count">Hit Count</sp-field-label>
            <eomap-number-field
              id="hit_count"
              max="${CHAR_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="unk3">Unk3</sp-field-label>
            <eomap-number-field
              id="unk3"
              max="${CHAR_MAX - 1}"
            ></eomap-number-field>
          </div>
        </div>
        <div class="field-row">
          <div class="field-container">
            <sp-field-label for="blend_id">Blend ID</sp-field-label>
            <eomap-number-field
              id="blend_id"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="tile_id">Tile ID</sp-field-label>
            <eomap-number-field
              id="tile_id"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="graphic_id">Graphic ID</sp-field-label>
            <eomap-number-field
              id="graphic_id"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
        </div>
        <div class="field-row">
          <div class="field-container">
            <sp-field-label for="item_id">Item ID</sp-field-label>
            <eomap-number-field
              id="item_id"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="time">Time</sp-field-label>
            <eomap-number-field
              id="time"
              max="${SHORT_MAX - 1}"
            ></eomap-number-field>
          </div>
          <div class="field-container">
            <sp-field-label for="unk11">Unk11</sp-field-label>
            <eomap-number-field
              id="unk11"
              max="${CHAR_MAX - 1}"
            ></eomap-number-field>
          </div>
        </div>
        <div class="field-row">
          <div class="field-container">
            <sp-field-label for="max_amount">Max Amount</sp-field-label>
            <eomap-number-field
              id="max_amount"
              max="${CHAR_MAX - 1}"
            ></eomap-number-field>
          </div>
        </div>
      </eomap-modal>
    `;
  }

  reset() {
    if (this.type) this.type.value = undefined;
    if (this.hit_count) this.hit_count.value = undefined;
    if (this.unk3) this.unk3.value = undefined;
    if (this.blend_id) this.blend_id.value = undefined;
    if (this.tile_id) this.tile_id.value = undefined;
    if (this.graphic_id) this.graphic_id.value = undefined;
    if (this.item_id) this.item_id.value = undefined;
    if (this.time) this.time.value = undefined;
    if (this.unk11) this.unk11.value = undefined;
    if (this.max_amount) this.max_amount.value = undefined;
  }

  populate(gather) {
    this.reset();
    if (this.type) this.type.value = gather.type;
    if (this.hit_count) this.hit_count.value = gather.hit_count;
    if (this.unk3) this.unk3.value = gather.unk3;
    if (this.blend_id) this.blend_id.value = gather.blend_id;
    if (this.tile_id) this.tile_id.value = gather.tile_id;
    if (this.graphic_id) this.graphic_id.value = gather.graphic_id;
    if (this.item_id) this.item_id.value = gather.id;
    if (this.time) this.time.value = gather.time;
    if (this.unk11) this.unk11.value = gather.unk11;
    if (this.max_amount) this.max_amount.value = gather.max_amount;
  }

  validateFields() {
    let valid = true;
    const fields = [
      this.type,
      this.hit_count,
      this.blend_id,
      this.tile_id,
      this.graphic_id,
      this.item_id,
      this.time,
      this.max_amount,
    ];
    for (let field of fields) {
      if (isNaN(field.value)) {
        field.invalid = true;
        valid = false;
      } else {
        field.invalid = false;
      }
    }
    return valid;
  }

  confirm(_event) {
    if (this.validateFields()) {
      const gatherData = {
        type: this.type.value,
        hit_count: this.hit_count.value,
        unk3: this.unk3.value,
        blend_id: this.blend_id.value,
        tile_id: this.tile_id.value,
        graphic_id: this.graphic_id.value,
        item_id: this.item_id.value,
        time: this.time.value,
        unk11: this.unk11.value,
        max_amount: this.max_amount.value,
      };
      this.open = false;
      this.dispatchEvent(new CustomEvent("save", { detail: gatherData }));
    }
  }

  cancel(_event) {
    this.open = false;
  }

  close(_event) {
    this.open = false;
    this.dispatchEvent(new CustomEvent("close"));
  }
}
