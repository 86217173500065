import { arrayEquals, binaryInsert, removeFirst } from "../util/array-utils";
import { TileSpec } from "../data/emf";
import { layerInfo } from "../data/layer-info";
import { GridType } from "../gfx/texture-cache";
import { rgbToHex } from "../util/color-utils";

const SECTION_SIZE = 256;

export const TDG = 0.00000001; // gap between depth of each tile on a layer
export const RDG = 0.001; // gap between depth of each row of tiles

const depthComparator = (a, b) => a.depth - b.depth;

class TileGraphic {
  constructor(cacheEntry, x, y, layer, depth, alpha) {
    this.cacheEntry = cacheEntry;
    this.x = x;
    this.y = y;
    this.layer = layer;
    this.depth = depth;
    this.alpha = alpha;
  }

  copy() {
    return new TileGraphic(
      this.cacheEntry,
      this.x,
      this.y,
      this.layer,
      this.depth,
      this.alpha
    );
  }

  get width() {
    return this.cacheEntry.asset.width;
  }

  get height() {
    return this.cacheEntry.asset.height;
  }
}

class EntityMap {
  constructor(width, height) {
    this.width = width;
    this.height = height;
    this.sparseArray = {};
  }

  get(x, y) {
    let index = this.getIndex(x, y);
    let entities = this.sparseArray[index];
    if (entities === undefined) {
      entities = [];
    }
    return entities;
  }

  set(x, y, entities) {
    let index = this.getIndex(x, y);
    this.sparseArray[index] = entities;
    if (entities.length === 0) {
      delete this.sparseArray[index];
    }
  }

  add(entity) {
    let index = this.getIndex(entity.x, entity.y);
    if (this.sparseArray[index] === undefined) {
      this.sparseArray[index] = [];
    }
    this.sparseArray[index].push(entity);
  }

  getIndex(x, y) {
    return y * this.width + x;
  }
}

class CachedFrame {
  constructor(renderTexture) {
    this.renderTexture = renderTexture;
    this.dirty = true;
  }

  destroy() {
    this.renderTexture.destroy();
    this.renderTexture = null;
  }
}

export class EOMap extends Phaser.GameObjects.GameObject {
  constructor(scene, textureCache, emf, layerVisibility) {
    super(scene, "EOMap");

    this.textureCache = textureCache;
    this.emf = emf;
    this.layerVisibility = layerVisibility;
    this.specialLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Special"
    );
    this.effectsLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Effects"
    );
    this.raisedLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Raised"
    );
    this.warpLayerIndex = layerInfo.findIndex((layer) => layer.name === "Warp");
    this.signLayerIndex = layerInfo.findIndex((layer) => layer.name === "Sign");
    this.itemLayerIndex = layerInfo.findIndex((layer) => layer.name === "Item");
    this.npcLayerIndex = layerInfo.findIndex((layer) => layer.name === "NPC");
    this.gridLayerIndex = layerInfo.findIndex((layer) => layer.name === "Grid");
    this.downWallLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Down Wall"
    );
    this.wallObjectLayerIndex = layerInfo.findIndex(
      (layer) => layer.name === "Wall Object"
    );

    this.x = 0;
    this.y = 0;
    this.drawScale = 1.0;

    this.camera = new Phaser.Cameras.Scene2D.Camera().setScene(scene);
    this.selectedLayer = 0;
    this.sections = null;
    this.sectionWidth = null;
    this.sectionHeight = null;
    this.visibleSections = null;
    this.items = null;
    this.npcs = null;
    this.tileGraphics = null;
    this.renderList = null;
    this.cachedFrame = null;
    this.animationFrame = 0;

    this.renderListChangesSinceLastTick = 0;
    this.dirtyRenderList = false;

    this._tempMatrix1 = new Phaser.GameObjects.Components.TransformMatrix();
    this._tempMatrix2 = new Phaser.GameObjects.Components.TransformMatrix();

    this.init();
    this.initPipeline();
  }

  init() {
    for (let i in this.tileGraphics) {
      this.tileGraphics[i].cacheEntry.decRef();
    }

    this.sections = [];
    this.sectionWidth = Math.ceil((this.emf.width * 64) / SECTION_SIZE);
    this.sectionHeight = Math.ceil((this.emf.height * 32) / SECTION_SIZE);
    this.visibleSections = [];
    this.items = new EntityMap(this.emf.width, this.emf.height);
    this.npcs = new EntityMap(this.emf.width, this.emf.height);
    this.tileGraphics = {};
    this.renderList = [];

    this.initSections();
    this.initEntityMaps();
    this.initTileGraphics();

    this.cull();
  }

  initSections() {
    let sectionCount = this.sectionWidth * this.sectionHeight;
    for (let i = 0; i < sectionCount; ++i) {
      this.sections.push(new Set());
    }
  }

  initEntityMaps() {
    for (let item of this.emf.items) {
      this.items.add(item);
    }

    for (let npc of this.emf.npcs) {
      this.npcs.add(npc);
    }
  }

  initTileGraphics() {
    for (let y = 0; y < this.emf.height; ++y) {
      for (let x = 0; x < this.emf.width; ++x) {
        let tile = this.emf.getTile(x, y);
        for (let layer = 0; layer < this.specialLayerIndex; ++layer) {
          let gfx = tile.gfx[layer];
          this.setGraphic(x, y, gfx, layer);
        }
        this.setSpec(x, y, tile.spec);
        this.setWarp(x, y, tile.warp);
        this.setEffect(x, y, tile.effect);
        this.setSign(x, y, tile.sign);
        this.setItems(x, y, this.items.get(x, y));
        this.setNPCs(x, y, this.npcs.get(x, y));
        this.setGrid(x, y);
      }
    }
  }

  rebuildRenderList() {
    let visibleGraphicIndices = new Set();
    for (let section of this.visibleSections) {
      for (let graphicIndex of section) {
        let layer = this.tileGraphics[graphicIndex].layer;
        if (this.layerVisibility.isLayerVisible(layer)) {
          visibleGraphicIndices.add(graphicIndex);
        }
      }
    }

    this.renderList = Array.from(visibleGraphicIndices, (index) => {
      let tileGraphic = this.tileGraphics[index];
      tileGraphic.alpha = this.calcAlpha(tileGraphic.layer);
      return tileGraphic;
    });

    this.renderList.sort(depthComparator);

    this.dirtyRenderList = false;
  }

  calcDepth(x, y, layer) {
    return layerInfo[layer].depth + y * RDG + x * layerInfo.length * TDG;
  }

  calcAlpha(layer) {
    let alpha = layerInfo[layer].alpha;

    if (
      layer === this.specialLayerIndex &&
      this.selectedLayer === this.specialLayerIndex
    ) {
      alpha *= 3;
    }
    return alpha;
  }

  getSection(x, y) {
    return this.sections[y * this.sectionWidth + x];
  }

  findSections(tileGraphic, x, y) {
    let halfWidth = this.emf.width * 32; // Center the coordinates
    let graphicWidth = tileGraphic.width; // Full width of the graphic
    let graphicHeight = tileGraphic.height; // Full height of the graphic

    // Calculate the left, right, top, and bottom section bounds
    let left = Math.trunc((tileGraphic.x + halfWidth) / SECTION_SIZE);
    let right = Math.trunc(
      (tileGraphic.x + halfWidth + graphicWidth) / SECTION_SIZE
    );
    let top = Math.trunc(tileGraphic.y / SECTION_SIZE);
    let bottom = Math.trunc((tileGraphic.y + graphicHeight) / SECTION_SIZE);

    // Clamp the values to section limits to prevent out-of-bounds errors
    top = Math.min(this.sectionHeight - 1, Math.max(0, top));
    bottom = Math.min(this.sectionHeight - 1, Math.max(0, bottom));
    left = Math.min(
      this.sectionWidth - 1,
      Math.max(-halfWidth / SECTION_SIZE, left)
    );
    right = Math.min(
      this.sectionWidth - 1,
      Math.max(-halfWidth / SECTION_SIZE, right)
    );

    // Collect the relevant sections
    let sections = [];
    for (let sectionY = top; sectionY <= bottom; ++sectionY) {
      for (let sectionX = left; sectionX <= right; ++sectionX) {
        let section = this.getSection(sectionX, sectionY);
        sections.push(section);
      }
    }
    return sections;
  }

  draw(x, y, drawID, layer) {
    if (layer >= 0 && layer <= this.specialLayerIndex - 1) {
      this.setGraphic(x, y, drawID, layer);
      return;
    }

    if (layer === this.specialLayerIndex) {
      this.setSpec(x, y, drawID);
      return;
    }

    throw new Error(`Invalid draw layer: ${layer}`);
  }

  async exportToImage(fileName) {
    const mapWidth = this.emf.width * 64; // Full width of the map
    const mapHeight = this.emf.height * 32; // Full height of the map

    const chunkWidth = this.camera.width - 1; // Size of each chunk
    const chunkHeight = this.camera.height - 1;

    // Calculate isometric offsets to center the map correctly
    const isoOffsetX = Math.floor(this.emf.width * 32) - 32; // Half the map width for isometric offset
    const isoOffsetY = 98; // Adjust as needed based on the map structure

    // Create the master canvas to accommodate the full map including any potential offset
    const masterCanvas = document.createElement("canvas");
    masterCanvas.width = mapWidth;
    masterCanvas.height = mapHeight + isoOffsetY * 2;
    const masterContext = masterCanvas.getContext("2d");

    masterContext.imageSmoothingEnabled = false;

    // Calculate the number of chunks required to cover the full map
    const numChunksX = Math.ceil(masterCanvas.width / chunkWidth);
    const numChunksY = Math.ceil(masterCanvas.height / chunkHeight);

    // Render each chunk and paste it to the master canvas
    for (let chunkY = 0; chunkY < numChunksY; chunkY++) {
      for (let chunkX = 0; chunkX < numChunksX; chunkX++) {
        // Calculate the correct offset for each chunk
        const offsetX = chunkX * chunkWidth - isoOffsetX;
        const offsetY = chunkY * chunkHeight - isoOffsetY;

        // Create a render texture for each chunk
        const renderTexture = this.scene.make.renderTexture({
          x: 0,
          y: 0,
          width: chunkWidth,
          height: chunkHeight,
          add: false,
        });

        // Adjust the camera to render this chunk
        this.camera.scrollX = chunkX * chunkWidth - isoOffsetX;
        this.camera.scrollY = chunkY * chunkHeight - isoOffsetY;

        await new Promise((resolve) => setTimeout(resolve, 50));

        // Begin drawing the chunk
        renderTexture.beginDraw();

        // Sort the render list by depth to ensure proper rendering order
        this.renderList.sort((a, b) => a.depth - b.depth);

        // Render each tile within the chunk
        for (let tileGraphic of this.renderList) {
          if (!this.layerVisibility.isLayerVisible(tileGraphic.layer)) {
            continue;
          }

          const asset = tileGraphic.cacheEntry.asset;

          if (!asset || !asset.getFrame) {
            console.error(
              `Missing asset or frame for tileGraphic at (${tileGraphic.x}, ${tileGraphic.y}), skipping...`
            );
            continue;
          }

          const frame = asset.getFrame(this.animationFrame);
          if (!frame) {
            console.error(
              `Frame not found for tileGraphic at (${tileGraphic.x}, ${tileGraphic.y}), skipping...`
            );
            continue;
          }

          // Adjust tile coordinates to account for camera scroll
          const adjustedX = tileGraphic.x - this.camera.scrollX;
          const adjustedY = tileGraphic.y - this.camera.scrollY;

          this.batchDrawFrame(
            renderTexture,
            frame,
            adjustedX,
            adjustedY,
            tileGraphic.alpha
          );
        }

        // End drawing on the render texture
        renderTexture.endDraw();

        // Take a snapshot of the chunk and draw it onto the master canvas
        const chunkImage = await new Promise((resolve) => {
          renderTexture.snapshot((image) => resolve(image));
        });

        // Draw the chunk onto the master canvas at the correct position
        masterContext.drawImage(
          chunkImage,
          Math.floor(chunkX * chunkWidth),
          Math.floor(chunkY * chunkHeight)
        );

        // Clean up the render texture
        renderTexture.destroy();
      }
    }

    // Convert the master canvas to a data URL
    const dataURL = masterCanvas.toDataURL("image/png");

    // Trigger the download of the final image
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = fileName || "map_export.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Reset the camera scroll for the editor
    this.camera.scrollX = 0;
    this.camera.scrollY = 0;
  }

  dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = dataURL.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  getDrawID(x, y, layer) {
    if (layer >= 0 && layer <= this.specialLayerIndex - 1) {
      return this.emf.getTile(x, y).gfx[layer];
    }

    if (layer === this.specialLayerIndex) {
      return this.emf.getTile(x, y).spec;
    }

    throw new Error(`Invalid draw layer: ${layer}`);
  }

  setGraphic(x, y, gfx, layer) {
    if (gfx === 0 && layer !== 0) {
      return;
    }

    this.emf.getTile(x, y).gfx[layer] = gfx;

    let cacheEntry = null;
    if (gfx) {
      let fileID = layerInfo[layer].fileID;
      let resourceID = gfx + 100;

      cacheEntry = this.textureCache.getResource(fileID, resourceID, layer);
      if (!cacheEntry) {
        console.warn("Could not load gfx %d/%d.", gfx, file);
        return;
      }
    }

    this.setTileGraphic(x, y, layer, cacheEntry);
  }

  setSpec(x, y, tileSpec) {
    let tile = this.emf.getTile(x, y);
    let oldTileSpec = tile.spec;

    tile.spec = tileSpec;

    if (oldTileSpec === TileSpec.Chest || tile.spec === TileSpec.Chest) {
      this.updateItemGraphic(x, y);
    }

    let cacheEntry = null;

    if (tileSpec !== null) {
      cacheEntry = this.textureCache.getSpec(tileSpec);
      if (!cacheEntry) {
        console.warn("Could not load tileSpec %d.", tileSpec);
        return;
      }
    }

    this.setTileGraphic(x, y, this.specialLayerIndex, cacheEntry);
    //this.setTileGraphic(x, y, this.specialLayerIndex + 1, cacheEntry);
  }

  getWarp(x, y) {
    return this.emf.getTile(x, y).warp;
  }

  setWarp(x, y, warp) {
    let tile = this.emf.getTile(x, y);
    tile.warp = warp;

    let cacheEntry = null;

    if (warp) {
      let entityType;
      switch (warp.spec) {
        case 0:
          entityType = "warp";
          break;
        case 1:
          entityType = "door";
          break;
        case 4:
          entityType = "transport";
          break;
        default:
          entityType = "lockeddoor";
      }
      cacheEntry = this.textureCache.getEntity(entityType);
    }

    this.setTileGraphic(x, y, this.warpLayerIndex, cacheEntry);
  }

  getEntityAtTile(x, y) {
    // Check for warp
    const warp = this.getWarp(x, y);
    if (warp) {
      return { type: "warp", data: warp };
    }

    // Check for items
    const items = this.getItems(x, y);
    if (items.length > 0) {
      return { type: "item", data: items };
    }

    // Check for NPCs
    const npcs = this.getNPCs(x, y);
    if (npcs.length > 0) {
      return { type: "npc", data: npcs };
    }

    // Check for signs
    const sign = this.getSign(x, y);
    if (sign) {
      return { type: "sign", data: sign };
    }

    const gather = this.getGather(x, y);
    if (gather) {
      return { type: "gather", data: gather };
    }

    return null;
  }

  colorArray = [
    rgbToHex(255, 255, 255), // a2 = 0
    rgbToHex(255, 245, 180), // a2 = 1
    rgbToHex(255, 255, 0), // a2 = 2
    rgbToHex(255, 180, 0), // a2 = 3
    rgbToHex(255, 0, 0), // a2 = 4
    rgbToHex(255, 0, 127), // a2 = 5
    rgbToHex(255, 155, 100), // a2 = 6
    rgbToHex(200, 60, 240), // a2 = 7
    rgbToHex(127, 0, 255), // a2 = 8
    rgbToHex(0, 0, 255), // a2 = 9
    rgbToHex(175, 200, 255), // a2 = 10
    rgbToHex(220, 245, 255), // a2 = 11
    rgbToHex(100, 255, 255), // a2 = 12
    rgbToHex(100, 255, 200), // a2 = 13
    rgbToHex(0, 255, 0), // a2 = 14
    rgbToHex(175, 255, 50), // a2 = 15
    rgbToHex(100, 100, 100), // a2 = 16
    rgbToHex(8, 8, 8), // Default case
  ];

  getEffect(x, y) {
    console.log("Getting effect at: ", x, y);
    console.log("Effect: ", this.emf.getTile(x, y).effect);
    return this.emf.getTile(x, y).effect;
  }

  setEffect(x, y, effect) {
    if (!effect || this.effectsLayerIndex === -1) {
      return;
    }
    let tile = this.emf.getTile(x, y);
    tile.effect = effect;

    let cacheEntry = null;

    const lightRadius = 4 * effect.scale;
    const lightIntensity = effect.size;
    const lightEffect = { type: effect.type, speed: 0, magnitude: 0.2 };
    const lightColor = this.colorArray[effect.color];

    cacheEntry = this.textureCache.getEntity("effect");
    this.setTileGraphic(x + 5, y + 2, this.effectsLayerIndex, cacheEntry);
    /*cacheEntry = this.textureCache.getEffect(
      lightEffect.type,
      lightColor,
      lightRadius,
      lightIntensity
    );
    //const childOffset = effect.yoffset / 3;
    this.setTileGraphic(x - 6, y - 2, this.effectsLayerIndex, cacheEntry); //- (effect.childEffectYOffset / 32)*/
  }

  getSign(x, y) {
    return this.emf.getTile(x, y).sign;
  }

  setSign(x, y, sign) {
    let tile = this.emf.getTile(x, y);
    tile.sign = sign;

    let cacheEntry = null;

    if (sign) {
      cacheEntry = this.textureCache.getEntity("sign");
    }

    this.setTileGraphic(x, y, this.signLayerIndex, cacheEntry);
  }

  getItems(x, y) {
    return [...this.items.get(x, y)];
  }

  updateItemGraphic(x, y) {
    let items = this.items.get(x, y);
    let cacheEntry = null;

    if (items.length > 0) {
      let entityKey = "items";
      if (this.emf.getTile(x, y).spec === TileSpec.Chest) {
        entityKey = "chest";
      }
      cacheEntry = this.textureCache.getEntity(entityKey);
    }

    this.setTileGraphic(x, y, this.itemLayerIndex, cacheEntry);
  }

  setItems(x, y, items) {
    let oldItems = this.items.get(x, y);
    this.items.set(x, y, items);
    this.emf.items = this.emf.items.filter((item) => !oldItems.includes(item));
    this.emf.items = this.emf.items.concat(items);
    this.updateItemGraphic(x, y);
  }

  getNPCs(x, y) {
    return [...this.npcs.get(x, y)];
  }

  setNPCs(x, y, npcs) {
    let oldNPCs = this.npcs.get(x, y);
    this.npcs.set(x, y, npcs);
    this.emf.npcs = this.emf.npcs.filter((npc) => !oldNPCs.includes(npc));
    this.emf.npcs = this.emf.npcs.concat(npcs);

    let cacheEntry = null;

    if (npcs.length > 0) {
      cacheEntry = this.textureCache.getEntity("npc");
    }

    this.setTileGraphic(x, y, this.npcLayerIndex, cacheEntry);
  }

  getGather(x, y) {
    return (
      this.emf.gathers.find((gather) => gather.x === x && gather.y === y) ||
      null
    );
  }

  setGather(x, y, gather) {
    this.emf.gathers = this.emf.gathers.filter(
      (gatherEntity) => !(gatherEntity.x === x && gatherEntity.y === y)
    );
    if (gather) {
      this.emf.gathers.push(gather);
    }
  }

  setGrid(x, y) {
    let isMaxX = x === this.emf.width - 1;
    let isMaxY = y === this.emf.height - 1;

    let gridType = GridType.Normal;
    if (isMaxX && isMaxY) {
      gridType = GridType.All;
    } else if (isMaxX) {
      gridType = GridType.Right;
    } else if (isMaxY) {
      gridType = GridType.Down;
    }

    this.setTileGraphic(
      x,
      y,
      this.gridLayerIndex,
      this.textureCache.getGridTile(gridType)
    );
  }

  getTileGraphicIndex(x, y, layer) {
    return (y * this.emf.width + x) * layerInfo.length + layer;
  }

  setTileGraphic(x, y, layer, cacheEntry) {
    let graphicIndex = this.getTileGraphicIndex(x, y, layer);
    let oldGraphic = this.tileGraphics[graphicIndex];

    if (!cacheEntry) {
      if (oldGraphic) {
        for (let section of this.findSections(oldGraphic)) {
          section.delete(graphicIndex);
        }

        delete this.tileGraphics[graphicIndex];

        if (!this.dirtyRenderList) {
          removeFirst(this.renderList, oldGraphic);
          if (++this.renderListChangesSinceLastTick > 100) {
            this.dirtyRenderList = true;
          }
        }

        oldGraphic.cacheEntry.decRef();
        this.checkEntityOffsets(x, y, layer);
        this.invalidateCachedFrame();
      }
      return;
    }

    cacheEntry.incRef();

    let tileGraphic = null;

    if (oldGraphic) {
      tileGraphic = oldGraphic.copy();
    } else {
      tileGraphic = new TileGraphic(
        cacheEntry,
        0,
        0,
        layer,
        this.calcDepth(x, y, layer),
        0.0
      );
    }

    this.tileGraphics[graphicIndex] = tileGraphic;

    let loaded = cacheEntry.loadingComplete || Promise.resolve();
    loaded.then(() => {
      if (oldGraphic) {
        oldGraphic.cacheEntry.decRef();
      }
      let currentGraphic = this.tileGraphics[graphicIndex];
      if (tileGraphic === currentGraphic) {
        for (let section of this.findSections(tileGraphic)) {
          section.delete(graphicIndex);
        }
        tileGraphic.cacheEntry = cacheEntry;
        tileGraphic.alpha = this.calcAlpha(layer);
        this.updateTileGraphicPosition(x, y, layer, tileGraphic);
        this.addTileGraphic(x, y, layer, tileGraphic);
      }
    });
  }

  addTileGraphic(x, y, layer, tileGraphic) {
    let graphicIndex = this.getTileGraphicIndex(x, y, layer);

    for (let section of this.findSections(tileGraphic)) {
      if (!section) {
        continue;
      }
      section.add(graphicIndex);
    }

    this.tileGraphics[graphicIndex] = tileGraphic;

    if (!this.dirtyRenderList && this.layerVisibility.isLayerVisible(layer)) {
      binaryInsert(this.renderList, tileGraphic, depthComparator);
      if (++this.renderListChangesSinceLastTick > 100) {
        this.dirtyRenderList = true;
      }
    }

    this.checkEntityOffsets(x, y, layer);
    this.checkRaisedOffsets(x, y, layer);
    this.checkEffectOffsets(x, y, layer);
    this.invalidateCachedFrame();
  }

  updateTileGraphicPosition(x, y, layer, tileGraphic) {
    let info = layerInfo[layer];
    tileGraphic.x = info.xoff + x * 32 - y * 32;
    tileGraphic.y = info.yoff + x * 16 + y * 16;

    if (
      layer === this.wallObjectLayerIndex &&
      this.getDrawID(x, y, this.downWallLayerIndex) !== null
    ) {
      tileGraphic.x -= info.rightWallxoff;
    }

    if (info.centered) {
      tileGraphic.x -= Math.floor(tileGraphic.cacheEntry.asset.width / 2) - 32;
    }

    if (info.bottomOrigin) {
      tileGraphic.y -= tileGraphic.cacheEntry.asset.height - 32;
    }
  }

  checkEntityOffsets(x, y, layer) {
    const layerObject = layerInfo[layer];
    if (layerObject.isEntity) {
      this.updateEntityOffsets(x, y);
    }
  }

  checkEffectOffsets(x, y, layer) {
    if (layer === this.effectsLayerIndex) {
      let graphicIndex = this.getTileGraphicIndex(x, y, layer);
      let entityGraphic = this.tileGraphics[graphicIndex];
      if (entityGraphic) {
        for (let section of this.findSections(entityGraphic)) {
          section.delete(graphicIndex);
        }

        let info = layerInfo[this.effectsLayerIndex];
        let baseX = info.xoff + x * 32 - y * 32;
        let baseY = info.yoff + x * 16 + y * 16;
        entityGraphic.x = baseX;
        entityGraphic.y = baseY;

        for (let section of this.findSections(entityGraphic)) {
          section.add(graphicIndex);
        }
      }
    }
  }

  checkRaisedOffsets(x, y, layer) {
    if (layer === this.raisedLayerIndex) {
      let tileYOffset = this.emf.getTile(x, y).yoffset[layer];
      if (tileYOffset) {
        let graphicIndex = this.getTileGraphicIndex(x, y, layer);
        let entityGraphic = this.tileGraphics[graphicIndex];
        if (entityGraphic) {
          for (let section of this.findSections(entityGraphic)) {
            section.delete(graphicIndex);
          }

          entityGraphic.y = x * 16 + y * 16 - tileYOffset;

          for (let section of this.findSections(entityGraphic)) {
            section.add(graphicIndex);
          }
        }
      }
    }
  }

  updateEntityOffsets(x, y) {
    let offset = -10;

    let object = this.tileGraphics[this.getTileGraphicIndex(x, y, 1)];
    if (object) {
      offset = Math.min(offset, 10 - object.height);
    }

    const entityLayerIndexes = layerInfo
      .map((layer, index) => (layer.isEntity ? index : null))
      .filter((index) => index !== null);

    for (let layer of entityLayerIndexes) {
      let graphicIndex = this.getTileGraphicIndex(x, y, layer);
      let entityGraphic = this.tileGraphics[graphicIndex];
      if (entityGraphic) {
        for (let section of this.findSections(entityGraphic)) {
          section.delete(graphicIndex);
        }

        entityGraphic.y = x * 16 + y * 16 + offset;
        offset -= 28;

        for (let section of this.findSections(entityGraphic)) {
          section.add(graphicIndex);
        }
      }
    }
  }

  cull() {
    let halfWidth = this.emf.width * 32;
    let fullHeight = this.emf.height * 32;

    let midX = this.scrollX + this.width / 2;
    let midY = this.scrollY + this.height / 2;
    let displayWidth = this.camera.displayWidth;
    let displayHeight = this.camera.displayHeight;
    let cullTop = midY - displayHeight / 2;
    let cullBottom = cullTop + displayHeight;
    let cullLeft = midX - displayWidth / 2;
    let cullRight = cullLeft + displayWidth;

    if (cullBottom < 0) {
      cullTop = -displayHeight;
      cullBottom = 0;
    } else if (cullTop > fullHeight) {
      cullTop = fullHeight;
      cullBottom = cullTop + displayHeight;
    }

    if (cullRight < -halfWidth) {
      cullRight = -halfWidth;
      cullLeft = cullRight - displayWidth;
    } else if (cullLeft > halfWidth) {
      cullLeft = halfWidth;
      cullRight = cullLeft + displayHeight;
    }

    cullTop = Math.min(0, cullTop);
    cullBottom = Math.min(fullHeight, cullBottom);
    cullLeft = Math.max(-halfWidth, cullLeft);
    cullRight = Math.max(halfWidth, cullRight);

    let oldVisibleSections = this.visibleSections;
    this.visibleSections = [];

    for (let y = 0; y < this.sectionHeight; ++y) {
      let top = y * SECTION_SIZE;
      let bottom = top + SECTION_SIZE;
      for (let x = 0; x < this.sectionWidth; ++x) {
        let left = -halfWidth + x * SECTION_SIZE;
        let right = left + SECTION_SIZE;

        let isVisible =
          right >= cullLeft &&
          left <= cullRight &&
          bottom >= cullTop &&
          top <= cullBottom;

        if (isVisible) {
          this.visibleSections.push(this.getSection(x, y));
        }
      }
    }

    if (!arrayEquals(oldVisibleSections, this.visibleSections)) {
      this.dirtyRenderList = true;
    }
  }

  updateDrawScale() {
    this.drawScale = 0.5;
    while (this.drawScale < this.zoom) {
      this.drawScale *= 2;
    }
  }

  setLayerVisibility(layerVisibility) {
    this.layerVisibility = layerVisibility;
    this.dirtyRenderList = true;
  }

  setSelectedLayer(selectedLayer) {
    this.selectedLayer = selectedLayer;
    this.dirtyRenderList = true;
  }

  getFrame() {
    if (!this.cachedFrame) {
      let renderTexture = new Phaser.GameObjects.RenderTexture(this.scene);
      renderTexture.camera.roundPixels = true;
      this.cachedFrame = new CachedFrame(renderTexture);
    }

    if (this.cachedFrame.dirty) {
      this.drawFrame();
    }

    this.cachedFrame.renderTexture
      .setPosition(this.x, this.y)
      .setDisplaySize(this.width, this.height);

    return this.cachedFrame;
  }

  drawFrame() {
    this.cachedFrame.dirty = false;

    let drawSizeRatio = this.drawScale / this.zoom;
    let drawWidth = this.width * drawSizeRatio;
    let drawHeight = this.height * drawSizeRatio;

    let renderTexture = this.cachedFrame.renderTexture
      .setSize(drawWidth, drawHeight)
      .clear();

    renderTexture.texture.setFilter(Phaser.Textures.LINEAR);

    let cameraDirty = this.camera.dirty;
    this.camera.preRender();
    this.camera.dirty = cameraDirty;

    renderTexture.camera.zoom = this.drawScale;
    renderTexture.camera.scrollX = this.scrollX;
    renderTexture.camera.scrollY = this.scrollY;
    renderTexture.beginDraw();

    let worldPoint = this.camera.getWorldPoint(0, 0);
    let drawWorldPoint = renderTexture.camera.getWorldPoint(0, 0);
    let drawOffsetX = worldPoint.x - drawWorldPoint.x;
    let drawOffsetY = worldPoint.y - drawWorldPoint.y;

    for (let tileGraphic of this.renderList) {
      let asset = tileGraphic.cacheEntry.asset;
      let frame = asset.getFrame(this.animationFrame);

      this.batchDrawFrame(
        renderTexture,
        frame,
        tileGraphic.x - drawOffsetX,
        tileGraphic.y - drawOffsetY,
        tileGraphic.alpha
      );
    }
    renderTexture.endDraw();
  }

  batchDrawFrame(renderTexture, textureFrame, x, y, alpha) {
    x += renderTexture.frame.cutX;
    y += renderTexture.frame.cutY;

    let matrix = this._tempMatrix1;
    matrix.copyFrom(renderTexture.camera.matrix);

    let spriteMatrix = this._tempMatrix2;
    spriteMatrix.applyITRS(x, y, 0, 1, 1);
    spriteMatrix.e -= renderTexture.camera.scrollX;
    spriteMatrix.f -= renderTexture.camera.scrollY;

    matrix.multiply(spriteMatrix);

    if (renderTexture.camera.roundPixels) {
      matrix.e = Math.round(matrix.e);
      matrix.f = Math.round(matrix.f);
    }

    if (renderTexture.renderTarget) {
      let tint =
        (renderTexture.globalTint >> 16) +
        (renderTexture.globalTint & 0xff00) +
        ((renderTexture.globalTint & 0xff) << 16);
      renderTexture.pipeline.batchTextureFrame(
        textureFrame,
        0,
        0,
        tint,
        alpha,
        matrix,
        null
      );
    } else {
      this.batchTextureFrameCanvas(renderTexture, textureFrame, matrix, alpha);
    }
  }

  batchTextureFrameCanvas(renderTexture, frame, matrix, alpha) {
    let renderer = renderTexture.renderer;
    let ctx = renderer.currentContext;

    let cd = frame.canvasData;
    let frameX = cd.x;
    let frameY = cd.y;
    let frameWidth = frame.cutWidth;
    let frameHeight = frame.cutHeight;

    if (frameWidth > 0 && frameHeight > 0) {
      ctx.save();

      matrix.setToContext(ctx);
      ctx.globalCompositeOperation = "source-over";
      ctx.globalAlpha = alpha;
      ctx.imageSmoothingEnabled = !(
        !renderer.antialias || frame.source.scaleMode
      );

      ctx.drawImage(
        frame.source.image,
        frameX,
        frameY,
        frameWidth,
        frameHeight,
        0,
        0,
        frameWidth,
        frameHeight
      );

      ctx.restore();
    }
  }

  renderWebGL(renderer, _src, camera) {
    if (this.width > 0 && this.height > 0) {
      let renderTexture = this.getFrame().renderTexture;
      renderTexture.renderWebGL(renderer, renderTexture, camera);
    }
  }

  renderCanvas(renderer, _src, camera) {
    if (this.width > 0 && this.height > 0) {
      let renderTexture = this.getFrame().renderTexture;

      // See: https://github.com/photonstorm/phaser/pull/6141
      let antialias = renderer.antialias;
      renderer.antialias = true;

      renderTexture.renderCanvas(renderer, renderTexture, camera);

      renderer.antialias = antialias;
    }
  }

  update(_time, _delta) {
    if (this.camera.dirty) {
      this.cull();
      this.updateDrawScale();
      this.invalidateCachedFrame();
    }

    if (this.dirtyRenderList) {
      this.rebuildRenderList();
      this.invalidateCachedFrame();
    }

    this.renderListChangesSinceLastTick = 0;
    this.camera.dirty = false;

    this.updateAnimationFrame();
  }

  updateAnimationFrame() {
    let oldAnimationFrame = this.animationFrame;
    this.animationFrame = Math.trunc(performance.now() / 600) % 4;
    if (oldAnimationFrame !== this.animationFrame) {
      this.invalidateCachedFrame();
    }
  }

  invalidateCachedFrame() {
    if (this.cachedFrame) {
      this.cachedFrame.dirty = true;
    }
  }

  destroy(fromScene) {
    for (let index in this.tileGraphics) {
      this.tileGraphics[index].cacheEntry.decRef();
      delete this.tileGraphics[index];
    }

    this.tileGraphics = null;
    this.sections = null;
    this.camera.destroy();

    if (this.cachedFrame) {
      this.cachedFrame.destroy();
    }

    super.destroy(fromScene);
  }

  setSize(width, height) {
    let offsetRatio = 1.0 + Math.pow(this.zoom - 1, -1);
    this.scrollX += (this.camera.width - width) / 2 / offsetRatio;
    this.scrollY += (this.camera.height - height) / 2 / offsetRatio;
    this.camera.width = width;
    this.camera.height = height;
  }

  centerOnTile(x, y) {
    // Calculate the isometric offsets for the tile coordinates
    const isoOffsetX = (x - y) * 32;
    const isoOffsetY = (x + y) * 16;

    // Set the camera scroll to center the calculated position
    this.camera.scrollX = isoOffsetX - this.camera.width / 2;
    this.camera.scrollY = isoOffsetY - this.camera.height / 2;

    // Mark the camera as dirty to trigger an update
    this.camera.dirty = true;
  }

  get width() {
    return this.camera.width;
  }

  set width(value) {
    this.setSize(value, this.height);
  }

  get height() {
    return this.camera.height;
  }

  set height(value) {
    this.setSize(this.width, value);
  }

  get scrollX() {
    return this.camera.scrollX;
  }

  set scrollX(value) {
    this.camera.scrollX = value;
  }

  get scrollY() {
    return this.camera.scrollY;
  }

  set scrollY(value) {
    this.camera.scrollY = value;
  }

  get zoom() {
    return this.camera.zoom;
  }

  set zoom(value) {
    this.camera.zoom = value;
  }

  get shouldRender() {
    return !this.cachedFrame || this.cachedFrame.dirty;
  }
}

Phaser.Class.mixin(EOMap, [
  Phaser.GameObjects.Components.Transform,
  Phaser.GameObjects.Components.BlendMode,
  Phaser.GameObjects.Components.Depth,
  Phaser.GameObjects.Components.Pipeline,
]);

Phaser.GameObjects.GameObjectFactory.register(
  "eomap",
  function (scene, textureCache, emf, layerVisibility) {
    const map = new EOMap(scene, textureCache, emf, layerVisibility);
    this.displayList.add(map);
    return map;
  }
);
