import { html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import "@spectrum-web-components/field-group/sp-field-group.js";
import "@spectrum-web-components/field-label/sp-field-label.js";

import "./modal";
import "./textfield";
import "./picker";
import "./menu-item";
import "./menu-divider";
import "./slider";

@customElement("eomap-entity-effect")
export class EntityEffect extends LitElement {
  @query("#type", true)
  type;

  @query("#color", true)
  color;

  @query("#scale", true)
  scale;

  @query("#intensity", true)
  intensity;

  @query("#daymode_min", true)
  daymode_min;

  @query("#pulse_mode", true)
  pulse_mode;

  @query("#unk9", true)
  unk9;

  @query("#xoffset", true)
  xoffset;

  @query("#yoffset", true)
  yoffset;

  @query("#unk11", true)
  unk11;

  @query("#sub_light_mode", true)
  sub_light_mode;

  @query("#sub_light_scale", true)
  sub_light_scale;

  @query("#sub_light_intensity", true)
  sub_light_intensity;

  @property({ type: Boolean, reflect: true })
  open = false;

  @property({ type: String, reflect: true })
  headline;

  // Reactive property to track selected color
  @property({ type: String })
  selectedColor = "#FFFFFF"; // Default to white

  render() {
    return html`
      <eomap-modal
        confirm-label="Save"
        cancel-label="Cancel"
        .headline=${this.headline}
        .open=${this.open}
        .width=${544}
        @confirm=${this.confirm}
        @cancel=${this.cancel}
        @close=${this.close}
      >
        <style>
          .container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }

          .container > div {
            flex: 1 1 45%; /* Allows two fields per row */
            min-width: 200px; /* Ensures fields have a minimum width */
          }

          eomap-textfield {
            --spectrum-alias-single-line-width: 208px;
            margin-bottom: 8px;
          }

          .section-title {
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 5px;
            width: 100%;
          }
        </style>

        <div class="container">
          <!-- Group 1: Basic Settings -->
          <span class="section-title">Basic Settings</span>
          <div>${this.renderPicker("Type", "type", this.getTypeOptions())}</div>
          <div>
            ${this.renderPickerColorpicker(
              "Color",
              "color",
              this.getColorOptions()
            )}
          </div>
          <div>${this.renderSlider("Scale", "scale", 0, 100)}</div>
          <div>${this.renderSlider("Intensity", "intensity", 0, 100)}</div>
          <div>
            ${this.renderPicker(
              "Daymode Min",
              "daymode_min",
              this.getDaymodeOptions()
            )}
          </div>

          <!-- Group 2: Advanced Settings -->
          <span class="section-title">Advanced Settings</span>
          <div>
            ${this.renderPicker(
              "Pulse Mode",
              "pulse_mode",
              this.getPulseModeOptions()
            )}
          </div>
          <div>${this.renderField("Unk9", "unk9")}</div>
          <div>${this.renderField("X Offset", "xoffset")}</div>
          <div>${this.renderField("Y Offset", "yoffset")}</div>
          <div>${this.renderField("Unk11", "unk11")}</div>

          <!-- Group 3: Sub Light Settings -->
          <span class="section-title">Sub Light Settings</span>
          <div>${this.renderField("Sub Light Mode", "sub_light_mode")}</div>
          <div>${this.renderField("Sub Light Scale", "sub_light_scale")}</div>
          <div>
            ${this.renderField("Sub Light Intensity", "sub_light_intensity")}
          </div>
        </div>
      </eomap-modal>
    `;
  }

  renderField(label, id) {
    return html`
      <sp-field-label for="${id}">${label}</sp-field-label>
      <eomap-textfield id="${id}"></eomap-textfield>
    `;
  }

  renderSlider(label, id, min = 0, max = 100) {
    return html`
      <sp-field-label for="${id}">${label}</sp-field-label>
      <eomap-slider id="${id}" min="${min}" max="${max}"></eomap-slider>
    `;
  }

  renderPicker(label, id, options) {
    return html`
      <sp-field-label for="${id}">${label}</sp-field-label>
      <eomap-picker id="${id}">
        ${options.map(
          (option) => html`
            <eomap-menu-item label="${option.label}" value="${option.value}">
              <span style="vertical-align: middle;">${option.label}</span>
              <div
                style="width: 20px; height: 20px; background-color: ${option.color}; display: inline-block; border: 1px solid #000; margin-left: 2px;"
              ></div>
            </eomap-menu-item>
          `
        )}
      </eomap-picker>
    `;
  }

  renderPickerColorpicker(label, id, options) {
    return html`
      <sp-field-label for="${id}">${label}</sp-field-label>
      <div style="display: flex; align-items: center; gap: 10px;">
        <eomap-picker
          id="${id}"
          @menu-item-press="${(e) => this.updateSelectedColor(e)}"
        >
          ${options.map(
            (option) => html`
              <eomap-menu-item label="${option.label}" value="${option.value}">
                <span style="vertical-align: middle;">${option.label}</span>
              </eomap-menu-item>
            `
          )}
        </eomap-picker>
        <!-- Display selected color next to picker -->
        <div
          style="width: 20px; height: 20px; background-color: ${this
            .selectedColor}; border: 1px solid #000;"
        ></div>
      </div>
    `;
  }

  updateSelectedColor(event) {
    const selectedValue = event.target.value;
    const selectedOption = this.getColorOptions().find(
      (option) => option.value === selectedValue
    );

    if (selectedOption) {
      this.selectedColor = selectedOption.color; // Update the selected color to the new value
    }
  }

  /*
  Effect Types
1 default light, bi-layer gradient iso oval, the same you see around your player
10 radial normal flicker
11 radial lamp post
12 radial candle flicker
14 radial glow with twinkle sub-effect
15-16 radial pulsing
17 another radial pulsing? found behind wall in clubvv entrance
20 fireflies
21 vertical twinkle
22 ?
23 comet
24 twinkle
25 ?
40 angled light rays
41 horizontal light rays
45 binary streak
50 campfire
51 ?
52 steam/smoke
53 ?
55 ?
56 ?
57 ?
*/
  getTypeOptions() {
    return [
      { label: "Default Light", value: "1" },
      /*       { label: "Unknown 2", value: "2" },
      { label: "Unknown 3", value: "3" },
      { label: "Unknown 4", value: "4" },
      { label: "Unknown 5", value: "5" },
      { label: "Unknown 6", value: "6" },
      { label: "Unknown 7", value: "7" },
      { label: "Unknown 8", value: "8" },
      { label: "Unknown 9", value: "9" }, */
      { label: "Radial Light", value: "10" },
      { label: "Radial Lamp Post", value: "11" },
      { label: "Radial Candle Flicker", value: "12" },
      { label: "Radial with Embers", value: "13" },
      { label: "Radial with Twinkle", value: "14" },
      { label: "Radial Pulsing", value: "15" },
      { label: "Radial Pulsing Fast", value: "16" },
      { label: "Radial Pulsing Fast 2", value: "17" },
      { label: "Radial Pulsing Slow", value: "18" },
      { label: "Radial Pulsing Twinkle", value: "19" },
      { label: "Fireflies", value: "20" },
      { label: "Raining Twinkle", value: "21" },
      { label: "Horizontal Bars (Lamp Post without Radial)", value: "22" },
      { label: "Comet", value: "23" },
      { label: "Radial Light Other", value: "24" },
      { label: "Falling Figure", value: "25" },
      /*       { label: "Unknown 26", value: "26" },
      { label: "Unknown 27", value: "27" },
      { label: "Unknown 28", value: "28" },
      { label: "Unknown 29", value: "29" }, */
      { label: "Ceiling Light", value: "30" },
      /*       { label: "Unknown 31", value: "31" },
      { label: "Unknown 32", value: "32" },
      { label: "Unknown 33", value: "33" },
      { label: "Unknown 34", value: "34" },
      { label: "Unknown 35", value: "35" },
      { label: "Unknown 36", value: "36" },
      { label: "Unknown 37", value: "37" },
      { label: "Unknown 38", value: "38" },
      { label: "Unknown 39", value: "39" }, */
      { label: "Angled Light Rays", value: "40" },
      { label: "Horizontal Light Rays", value: "41" },
      /*       { label: "Unknown 42", value: "42" },
      { label: "Unknown 43", value: "43" },
      { label: "Unknown 44", value: "44" }, */
      { label: "Binary Streak", value: "45" },
      /*       { label: "Unknown 46", value: "46" },
      { label: "Unknown 47", value: "47" },
      { label: "Unknown 48", value: "48" },
      { label: "Unknown 49", value: "49" }, */
      { label: "Heat & Ember", value: "50" },
      { label: "Heat", value: "51" },
      { label: "Smoke", value: "52" },
      { label: "Ghastly Faces", value: "53" },
      /*       { label: "Unknown 54", value: "54" }, */
      { label: "Air Bubbles", value: "55" },
      /*       { label: "Unknown 56", value: "56" },
      { label: "Unknown 57", value: "57" }, */
      { label: "Unknown 58", value: "58" },
      { label: "Unknown 59", value: "59" },
      { label: "Unknown 60", value: "60" },
      { label: "Unknown 61", value: "61" },
      { label: "Unknown 62", value: "62" },
      { label: "Unknown 63", value: "63" },
      { label: "Unknown 64", value: "64" },
      { label: "Unknown 65", value: "65" },
      { label: "Unknown 66", value: "66" },
      { label: "Unknown 67", value: "67" },
      { label: "Unknown 68", value: "68" },
      { label: "Unknown 69", value: "69" },
      { label: "Unknown 70", value: "70" },
      { label: "Unknown 71", value: "71" },
      { label: "Unknown 72", value: "72" },
      { label: "Unknown 73", value: "73" },
      { label: "Unknown 74", value: "74" },
      { label: "Unknown 75", value: "75" },
      { label: "Unknown 76", value: "76" },
      { label: "Unknown 77", value: "77" },
      { label: "Unknown 78", value: "78" },
      { label: "Unknown 79", value: "79" },
      { label: "Unknown 80", value: "80" },
      { label: "Unknown 81", value: "81" },
      { label: "Unknown 82", value: "82" },
      { label: "Unknown 83", value: "83" },
      { label: "Unknown 84", value: "84" },
      { label: "Unknown 85", value: "85" },
      { label: "Unknown 86", value: "86" },
      { label: "Unknown 87", value: "87" },
      { label: "Unknown 88", value: "88" },
      { label: "Unknown 89", value: "89" },
      { label: "Unknown 90", value: "90" },
      { label: "Unknown 91", value: "91" },
      { label: "Unknown 92", value: "92" },
    ];
  }

  getColorOptions() {
    return [
      { label: "White", value: "0", color: "#FFFFFF" },
      { label: "Light Yellow", value: "1", color: "#FFF7D1" },
      { label: "Yellow", value: "2", color: "#FFFF00" },
      { label: "Orange", value: "3", color: "#FFA500" },
      { label: "Red", value: "4", color: "#FF0000" },
      { label: "Pink", value: "5", color: "#FF5F96" },
      { label: "Peach", value: "6", color: "#FFA07A" },
      { label: "Purple", value: "7", color: "#800080" },
      { label: "Violet", value: "8", color: "#8A2BE2" },
      { label: "Blue", value: "9", color: "#0000FF" },
      { label: "Light Blue", value: "10", color: "#ADD8E6" },
      { label: "Sky Blue", value: "11", color: "#87CEEB" },
      { label: "Cyan", value: "12", color: "#00FFFF" },
      { label: "Aqua", value: "13", color: "#7FFFD4" },
      { label: "Lime Green", value: "14", color: "#32CD32" },
      { label: "Light Green", value: "15", color: "#ADFF2F" },
      { label: "Gray", value: "16", color: "#808080" },
    ];
  }

  getDaymodeOptions() {
    return [
      { label: "Day", value: "0" },
      { label: "Dusk", value: "1" },
      { label: "Night", value: "2" },
      { label: "Dawn", value: "3" },
      { label: "Morning", value: "4" },
    ];
  }

  getPulseModeOptions() {
    return [
      { label: "Unk", value: "0" },
      { label: "Unk1", value: "1" },
      { label: "Unk2", value: "2" },
      { label: "Unk3", value: "3" },
      { label: "Unk4", value: "4" },
      { label: "Unk5", value: "5" },
      { label: "Unk6", value: "6" },
      { label: "Unk7", value: "7" },
      { label: "Unk8", value: "8" },
      { label: "Unk9", value: "9" },
      { label: "Unk10", value: "10" },
    ];
  }

  populate(effect) {
    if (this.type) this.type.value = effect.type;
    if (this.color) {
      this.color.value = effect.color;
      this.selectedColor =
        this.getColorOptions().find(
          (option) => option.value === String(effect.color)
        )?.color || "#FFFFFF";
    }
    if (this.scale) this.scale.value = effect.scale;
    if (this.intensity) this.intensity.value = effect.intensity;
    if (this.daymode_min) this.daymode_min.value = effect.daymode_min;
    if (this.pulse_mode) this.pulse_mode.value = effect.pulse_mode;
    if (this.unk9) this.unk9.value = effect.unk9;
    if (this.xoffset) this.xoffset.value = effect.xoffset;
    if (this.yoffset) this.yoffset.value = effect.yoffset;
    if (this.unk11) this.unk11.value = effect.unk11;
    if (this.sub_light_mode) this.sub_light_mode.value = effect.sub_light_mode;
    if (this.sub_light_scale)
      this.sub_light_scale.value = effect.sub_light_scale;
    if (this.sub_light_intensity)
      this.sub_light_intensity.value = effect.sub_light_intensity;
  }

  confirm(_event) {
    this.open = false;
    this.dispatchEvent(
      new CustomEvent("save", {
        detail: {
          type: parseInt(this.type.value),
          color: parseInt(this.color.value),
          scale: parseInt(this.scale.value),
          intensity: parseInt(this.intensity.value),
          daymode_min: parseInt(this.daymode_min.value),
          pulse_mode: parseInt(this.pulse_mode.value),
          unk9: parseInt(this.unk9.value),
          xoffset: parseInt(this.xoffset.value),
          yoffset: parseInt(this.yoffset.value),
          unk11: parseInt(this.unk11.value),
          sub_light_mode: parseInt(this.sub_light_mode.value),
          sub_light_scale: parseInt(this.sub_light_scale.value),
          sub_light_intensity: parseInt(this.sub_light_intensity.value),
        },
      })
    );
  }

  cancel(_event) {
    this.open = false;
  }

  close(_event) {
    this.open = false;
    this.dispatchEvent(new CustomEvent("close"));
  }
}
