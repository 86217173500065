import { CHAR_MAX, SHORT_MAX, THREE_MAX } from "./eo-numeric-limits";

import { reverse } from "../util/array-utils";

function getByteValue(byte) {
  if (byte === undefined) {
    byte = 254;
  }

  if (byte === 254) {
    byte = 1;
  }

  --byte;

  return byte & 0xff;
}

export function decodeNumber(a, b, c, d) {
  a = getByteValue(a);
  b = getByteValue(b);
  c = getByteValue(c);
  d = getByteValue(d);

  return d * THREE_MAX + c * SHORT_MAX + b * CHAR_MAX + a;
}

export function decodeStringNew(bytes) {
  let length = bytes.length;
  let otherStr = [];

  for (let i = 0; i < length; ++i) {
    let v5 = bytes[i];
    let v6;

    if ((i + 1) % 2 === (length + 1) % 2) {
      if (v5 >= 0x22 && v5 <= 0x7d) {
        v6 = 125 - v5 + 34;
        otherStr.push(v6);
      } else {
        otherStr.push(v5);
      }
    } else {
      if (v5 >= 0x22 && v5 <= 0x4f) {
        v6 = 79 - v5 + 34;
        otherStr.push(v6);
      } else if (v5 >= 0x50 && v5 <= 0x7d) {
        v6 = 125 - v5 + 80;
        otherStr.push(v6);
      } else {
        otherStr.push(v5);
      }
    }
  }

  // Reverse the decoded array
  otherStr.reverse();

  // Return the decoded byte array
  return otherStr;
}

export function decodeStringClassic(bytes) {
  let length = bytes.length;

  reverse(bytes);

  let flippy = length % 2 === 1;

  for (let i = 0; i < length; ++i) {
    let c = bytes[i];
    let f = 0;

    if (flippy) {
      f = 0x2e;
      if (c >= 0x50) {
        f *= -1;
      }
    }

    if (c >= 0x22 && c <= 0x7e) {
      bytes[i] = 0x9f - c - f;
    }

    flippy = !flippy;
  }
}

export function decodeString(bytes) {
  let length = bytes.length;
  let decodedStr = "";

  for (let i = 0; i < length; ++i) {
    // Apply the adjustment based on the index
    let additionValue = i % 2 === 0 ? 2 : 1;
    let adjustedValue = Math.min(bytes[i] + additionValue, 255);

    // Skip padding character (255)
    if (adjustedValue === 255) {
      continue;
    }

    // Append the character to the decoded string
    decodedStr += String.fromCharCode(adjustedValue);
  }

  return decodedStr;
}

export function encodeStringName(string, length = 32) {
  let bytes = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    if (i < string.length) {
      let charCode = string.charCodeAt(i);

      // Apply the inverse adjustment based on the index
      let subtractionValue = i % 2 === 0 ? 2 : 1;
      let adjustedValue = charCode - subtractionValue;

      // Ensure the value stays within byte bounds
      bytes[i] = Math.max(0, Math.min(adjustedValue, 255));
    } else {
      // Fill remaining bytes with 255 as a padding character
      bytes[i] = 255;
    }
  }

  return bytes;
}
