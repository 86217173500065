export function hexToRgba(hex, alpha = 1) {
  let r = 0,
    g = 0,
    b = 0;

  // Remove any leading '#' if present
  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    // 3-digit hex, e.g., #123
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    // 6-digit hex, e.g., #112233
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    // Invalid hex color
    throw new Error("Invalid hex color");
  }

  return `rgba(${r},${g},${b},${alpha})`;
}

export function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
